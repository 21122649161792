import axios from 'axios';
import { apiUrl } from '../../../environment';

export const getUserDetails = async (userId, authToken) => {
  try {
    const response = await axios.get(`${apiUrl}/users/${userId}`, {
      headers: {
        'authorization': authToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error(`Error fetching user details for user_id: ${userId}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user details: ${error.message}`);
    return null;
  }
};

// Get all users
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/users`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while fetching all users.');
  }
};

// Create new user
export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${apiUrl}/users/create`, userData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while creating the user.');
  }
};

// Update existing user
export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.put(`${apiUrl}/users/${userId}/update`, userData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while updating the user.');
  }
};

// Delete user by ID
export const deleteUser = async (userId) => {
  try {
    await axios.delete(`${apiUrl}/users/${userId}`);
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while deleting the user.');
  }
};

// Get user by email
export const getUserByEmail = async (email) => {
  try {
    const response = await axios.get(`${apiUrl}/users/email/${email}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while fetching user by email.');
  }
};

// Get password by email
export const getPasswordByEmail = async (email) => {
  try {
    const response = await axios.get(`${apiUrl}/users/password/${email}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while fetching password by email.');
  }
};

// Create new profile entries
export const createProfileEntries = async (entryData) => {
  try {
    const response = await axios.post(`${apiUrl}/profile-entries/create`, entryData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while creating the profile entries.');
  }
};

// Create new profile info
export const createProfileInfo = async (infoData) => {
  try {
    const response = await axios.post(`${apiUrl}/profile-info/create`, infoData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while creating the profile info.');
  }
};

export const userLogin = async (loginData) => {
  try {
    const response = await axios.post(`${apiUrl}/users/login`, loginData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Email or Password is incorrect. Please check the credentials and enter again!');
  }
};

export const updatePassword = async (emailId, newPassword) => {
  const userData = { password: newPassword };
  try {
    const response = await axios.put(`${apiUrl}/users/${emailId}/update/password`, userData, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while updating the password.');
  }
};

export const getUserPassword = async (emailId) => {
  try {
    const response = await axios.get(`${apiUrl}/users/${emailId}/password`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error('An error occurred while fetching the user password.');
  }
};

export const postUserDashboard = async () => {
  try {
    const response = await axios.post(
      `${apiUrl}/users/dashboard`,
      { user_id: localStorage.getItem('user_id') },
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': localStorage.authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error('An error occurred while posting user dashboard data.');
  }
};