import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../header/Header';
import { apiUrl } from "../../environment";
// not in use
const PatientDetails = () => {
  const { patient_id } = useParams();
  const [patientDetails, setPatientDetails] = useState(null);

  useEffect(() => {
    const fetchPatientDetails = async () => {
        try {
            const response = await fetch(`${apiUrl}/patient/${patient_id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
      
            if (response.ok) {
              const patient_details = await response.json();
              setPatientDetails([patient_details]);
            } else {
              throw new Error("Failed to fetch patient data");
            }
          } catch (error) {
            console.error("Error fetching patient data:", error);
          }
    };

    fetchPatientDetails();
  }, [patient_id]);

  if (!patientDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Header/>
      <h2>Patient Details</h2>
      <p>Name: {patientDetails[0].patient.name}</p>
      <p>Age: {patientDetails[0].patient.age}</p>
      <p>contact: {patientDetails[0].patient.contact}</p>
      <p>gender: {patientDetails[0].patient.gender}</p>
      {/* Add more details as needed */}
    </div>
  );
  
};

export default PatientDetails;
