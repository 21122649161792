import React, { useState } from 'react';
import { apiUrl } from '../../../environment';

const ImageEdit = ({ imageUrl, imageFormat, file_id, userAccountId, image_type }) => {
    const [editMode, setEditMode] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
  
    const handleDelete = async (file_id, e) => {
    e.preventDefault(); 
    try{
        const deleteResponse = await fetch(
            `${apiUrl}/images/${file_id}/status/update`,
            {
              method: "PUT",
              headers: { "authorization": localStorage.authorization },
            }
        );
          if (!deleteResponse.ok) {
            console.error("Failed to upload images!");
          }
    }
    catch(error){
        console.error(error);
    }
  };

  const handleEdit = (file_id, e) => {
    e.preventDefault();
    setEditMode(true);
  };

  const handleEditSubmit = async () => {
    try {
      if (selectedFile) {
        await uploadImage(selectedFile);
      }

    } catch (error) {
      console.error("Error updating user data:", error.message);
    }
  };

  const uploadImage = async (file) => {
    const form = new FormData();
    form.append("myImage", file);

    if (userAccountId) {
      form.append("user_account_id", userAccountId);
    }
    form.append("image_type", image_type);
    form.append("image_status", "active");
    form.append("image_format", file.type);

    try {
      const response = await fetch(`${apiUrl}/images/create`, {
        method: "POST",
        headers: { "authorization": localStorage.authorization },
        body: form,
      });

      if (response.ok) {
        const data = await response.json();
        setEditMode(false);
      } else {
        console.error("Failed to upload image:", response.status);
      }
    } catch (err) {
      console.error("Error uploading image:", err.message);
    }
  };

  
  return (
    <div>
        <div key={file_id} style={{ marginBottom: '20px' }}>
          <img src={imageUrl} alt="Uploaded Image" style={{ width: '150px', height: 'auto' }} />
          {/* <button onClick={(e) => handleEdit(file_id, e)}>Edit</button> */}
          &nbsp;
          <button onClick={(e) => handleDelete(file_id, e)} type='submit'>Delete</button>
        </div>
        {/* {editMode && (
                <div>
                   <input
                id="fileInput"
                type="file"
                name="myImage"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    console.log(e.target.files[0]);
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
               <button
                className="btn btn-primary p-2 my-3"
                onClick={handleEditSubmit}
              >
                Save
              </button>
                </div>
            )} */}
    </div>
  );
};

export default ImageEdit;
