import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../environment';
import Header from '../header/Header';
import ImageEdit from '../images/image-edit/ImageEdit';

const EditSubProcedure = ({onClose, onUpdate, sub_procedure_id}) => {
  const [newSubProcedureData, setNewSubProcedureData] = useState({
    sub_procedure_name: "",
    sub_procedure_type: "",
    serial_number: ""
    });
    const [procedure_id, setProcId] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [images, setImages] = useState([]);
  
    useEffect(() => {
      // Fetch procedure data based on procedure_id
      fetchSubProcedureData(sub_procedure_id);
    }, [sub_procedure_id]);

    const fetchSubProcedureData = async (subProcedureId) => {
      try {
        const subProcResponse = await fetch(`${apiUrl}/sub-procedures/${subProcedureId}`
        );
  
        if (subProcResponse.ok) {
          const subProcedureData = await subProcResponse.json();
          setProcId(subProcedureData.subProcedureRecord.procedure_id);
          setNewSubProcedureData({
            sub_procedure_name: subProcedureData.subProcedureRecord.name,
            sub_procedure_type: subProcedureData.subProcedureRecord.type,
            serial_number: subProcedureData.subProcedureRecord.serial_number
          })
          try {
            const userAccountId = subProcedureId;
            const image_type = 'sub_procedure_pic';
            const image_status = 'active';
            const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}/multiple`, {
              method: "GET",
              headers: { "authorization": localStorage.authorization },
            });
          
            if (imageResponse.ok) {
              let imageData = [];
              imageData = await imageResponse.json();
              setImages(imageData);
              } else {
                console.error("Failed to fetch images:", imageResponse.status);
                setImages([]);
              }
                    
          } catch (error) {
            console.error("Error fetching images:", error.message);
            setImages([]);
          }
        } else {
          console.error("Failed to fetch procedure data");
        }
      } catch (error) {
        console.error("Error fetching procedure data:", error);
      }
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      // Update the local state only if the value has changed
      if (newSubProcedureData[name] !== value) {
        setNewSubProcedureData({
          ...newSubProcedureData,
          [name]: value,
        });
      }
    };

    const handleImageUpload = (files) => {
      if (files) {
        const fileList = Object.values(files); // Convert object to array
        setSelectedFiles(fileList);
      }
    };

    const updateSubProcedureRecord = async (event) => {
      event.preventDefault();
  
      try {
        const response = await fetch(`${apiUrl}/sub-procedures/${sub_procedure_id}/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            procedure_id: procedure_id,
            name: newSubProcedureData.sub_procedure_name,
            type: newSubProcedureData.sub_procedure_type,
            serial_number: newSubProcedureData.serial_number
          }),
        }
        );
  
        if (response.ok) {
          if (selectedFiles.length > 0) {
            const formData = new FormData();
            formData.append("user_account_id", sub_procedure_id);
            if (Array.isArray(selectedFiles)) {
              selectedFiles.forEach((file, index) => {
                formData.append(`sub_proc_pic`, file);
                formData.append("image_format", file.type);
              });
            } else {
              console.error("selectedFiles is not an array");
            }
            formData.append("image_type", "sub_procedure_pic");
            formData.append("image_status", "active");
  
            const fieldName = "sub_proc_pic";
          const imageUploadResponse = await fetch(
            `${apiUrl}/images/create-multiple/${fieldName}`,
            {
              method: "POST",
              body: formData,
            }
          );
  
            if (!imageUploadResponse.ok) {
              console.error("Failed to upload images!");
            }
          }
          onUpdate();
          onClose();
        } else {
          console.error("Failed to update procedure record");
        }
      } catch (error) {
        console.error("Error updating procedure record:", error);
      }
    };

  return (
    <div>
         <form onSubmit={updateSubProcedureRecord}>
<div>
  <label htmlFor="sub_procedure_name">Sub Procedure Name</label>
  <input
    type="text"
    name="sub_procedure_name"
    className="form-control my-3"
    value={newSubProcedureData.sub_procedure_name}
    onChange={handleInputChange}
    required
  />
</div>
<div>
  <label htmlFor="sub_procedure_type">Sub Procedure Type</label>
  <input
    type="text"
    name="sub_procedure_type"
    className="form-control my-3"
    value={newSubProcedureData.sub_procedure_type}
    onChange={handleInputChange}
    required
  />
</div>
<div>
  <label htmlFor="serial_number">Serial Number</label>
  <input
    type="text"
    name="serial_number"
    className="form-control my-3"
    value={newSubProcedureData.serial_number}
    onChange={handleInputChange}
    required
  />
</div>
{Array.isArray(images) && images.length > 0 ? (
            images.map((image, index) => (
              <ImageEdit
                key={index}
                imageUrl={image.presignedURL}
                imageFormat={image.image_format}
                file_id={image.file_id}
                userAccountId={sub_procedure_id}
                image_type={'sub_procedure_pic'}
              />
            ))
          ) : (
            <p>No images available.</p>
          )}
    <br></br>
          <div>
            <label>Add a new file</label>
            <input
              type="file"
              name="sub_proc_pic"
              placeholder="Upload file for sub procedure"
              className="form-control my-3"
              accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
              onChange={(e) => handleImageUpload(e.target.files)}
              multiple
            />
          </div>
<button className="btn btn-success btn-block my-3" type="submit">
Update Sub Procedure  </button> &nbsp;
  <button
    className="btn btn-success btn-block my-3"
    onClick={onClose}
  >
    Cancel
  </button>
</form>
    </div>
  )
}

export default EditSubProcedure
