import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {userLogout} from '../../auth'
import Header from "../header/Header";
import { apiUrl } from "../../environment";
import { getAllApprovals } from "./services/NotificationsService";

const Notifications = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [approvalUsers, setApprovalUsers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [approvalId, setApprovalId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const userRole = localStorage.getItem("role");
      setIsAdmin(userRole === "admin");

      try {
        const response = await getAllApprovals();
        const data = await response;
        if (Array.isArray(data.approvals)) {
          setApprovals(data.approvals);
        } else {
          console.error("Approvals data is not an array:", data);
        }

        // Fetch user data and update the approvals with user data
        const approvalsWithUserData = await fetchUserData(data.approvals);
        setApprovalUsers(
          approvalsWithUserData.reduce((acc, approval) => {
            if (approval.user) {
              acc[approval.approval_id] = approval.user;
            }
            return acc;
          }, {})
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteModalOpen = (approvalId) => {
    setShowModal(true);
    setApprovalId(approvalId);
    // handleApprovalClick(approvalId);
  };

 const handleDeleteModalClose = () => {
  setShowModal(false);
  if (approvalId) {
    fetch(`${apiUrl}/${approvalUsers[approvalId].user_account_id}/delete`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          console.log("Approval rejected successfully and user info deleted");
        } else {
          console.error("Error rejecting approval:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error rejecting approval:", error);
      });
  }
  setApprovalId(null);
};

  const fetchUserData = async (approvalsToProcess) => {
    try {
      const allUsersData = JSON.parse(localStorage.getItem("allUsers")) || [];

      const approvalsWithUserData = await Promise.all(
        approvalsToProcess.map(async (approval) => {
          const user = allUsersData.find(
            (user) => user.user_account_id === approval.user_id
          );
          return { ...approval, user };
        })
      );

      return approvalsWithUserData;
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const handleApprovalClick = (approvalId) => {
    const approvalToUpdate = approvals.find(
      (approval) => approval.approval_id === approvalId
    );

    if (!approvalToUpdate) {
      console.error(`Approval with approval_id ${approvalId} not found.`);
      return;
    }

    const answer = window.confirm("Do you want to update this approval?");

    if (answer) {
      // Call the update API if 'Yes' is clicked
      fetch(`${apiUrl}/approvals/${approvalId}/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          approval: approvalToUpdate.user_id,
          status: "approved",
        }),
      })
        .then((response) => {
          // Handle successful update if needed
          console.log("Approval updated successfully");
        })
        .catch((error) => {
          // Handle error if needed
          console.error("Error updating approval:", error);
        });
    } else {
      // Do nothing if 'No' is clicked
    }
  };

  const logout = (e) => {
    e.preventDefault();
    try {
      userLogout();
      localStorage.removeItem("authorization");
      localStorage.removeItem("role");
      localStorage.removeItem("user_account_id");
      localStorage.removeItem("allUsers");
      console.clear();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div>
    <Header />
      {/* Render the notifications */}
      {approvals.length > 0 ? (
  approvals
    .filter((approval) => approval.status === "pending" || approval.status === "rejected")
    .filter((approval) => approval.user_id !== null)
    .map((approval) => (
      <div
        key={approval.approval_id}
        onClick={() => navigate(`/users/${approval.user_id}`)}
      >
        {approvalUsers[approval.approval_id] ? (
          `${approvalUsers[approval.approval_id].first_name} ${
            approvalUsers[approval.approval_id].last_name
          } has requested for the approval`
        ) : (
          "Loading user data..."
        )}
      </div>
    ))
) : (
  <div key={`no-records-${Date.now()}`}>No records found</div>
)}
    <Modal
        isOpen={showModal}
        onRequestClose={handleDeleteModalClose}
        contentLabel="Approve/Reject the request"
      >
        <h2>Approval Request</h2>
        <p>Do you want to apporve or reject?</p>
        <button onClick={handleDeleteModalOpen}>Approve</button>
        <button onClick={handleDeleteModalClose}>Reject</button>
    </Modal>
    </div>
  );
};

export default Notifications;
