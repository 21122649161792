import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { apiUrl } from "../../../environment";

const UsersCrud = () => {
  const [users, setUsers] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newUserData, setNewUserData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email_id: "",
    password: "",
    date_of_birth: "",
    gender: "",
    home_address: "",
    phone: "",
    role: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/allUsers`);
      const data = await response.json();
      setUsers(data.users);
      localStorage.setItem("allUsers", JSON.stringify(data.users));
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddUser = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/users/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUserData),
      });

      if (response.ok) {
        // Success: Refresh user data
        await fetchUserData();
        // Clear the form data
        setNewUserData({
          first_name: "",
          middle_name: "",
          last_name: "",
          email_id: "",
          password: "",
          date_of_birth: "",
          gender: "",
          home_address: "",
          phone: "",
          role: "",
        });
        setShowAddForm(false); // Hide the form fields after successful addition
      } else {
        // Error: Handle error cases
        console.error("Failed to add a new user.");
      }
    } catch (error) {
      console.error("Error adding a new user: ", error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const handleDeleteModalOpen = (userId) => {
    setShowModal(true);
    setDeleteUserId(userId);
  };

  const handleDeleteModalClose = () => {
    setShowModal(false);
    setDeleteUserId(null);
  };

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const handleEdit = (user) => {
    setEditUserId(user.user_account_id);
    setNewUserData({
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      email_id: user.email_id,
      // password: user.password,
      date_of_birth: user.date_of_birth,
      gender: user.gender,
      home_address: user.home_address || "",
      phone: user.phone_number || "",
      role: user.role,
    });
    setIsEditing(true);
    setShowAddForm(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/users/${editUserId}/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newUserData),
        }
      );

      if (response.ok) {
        // Success: Refresh user data and reset the editing state
        await fetchUserData();
        setIsEditing(false);
        setEditUserId(null);
        setNewUserData({
          first_name: "",
          middle_name: "",
          last_name: "",
          email_id: "",
          password: "",
          date_of_birth: "",
          gender: "",
          home_address: "",
          phone: "",
          role: "",
        });
        setShowAddForm(false);
      } else {
        // Error: Handle error cases
        console.error("Failed to update the user.");
      }
    } catch (error) {
      console.error("Error updating the user: ", error);
    }
  };

  const handleCancelEdit = () => {
    setNewUserData({
      first_name: "",
      middle_name: "",
      last_name: "",
      email_id: "",
      password: "",
      date_of_birth: "",
      gender: "",
      home_address: "",
      phone: "",
      role: "",
    });
    setIsEditing(false);
    setEditUserId(null);
    setShowAddForm(false);
  };

  const handleDeleteConfirm = async () => {
    if (deleteUserId) {
      // Call your delete API here
      try {
        const response = await fetch(`${apiUrl}/users/${deleteUserId}/delete`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          // Success: Refresh user data
          await fetchUserData();
        } else {
          // Error: Handle error cases
          console.error("Failed to delete the user.");
        }
      } catch (error) {
        console.error("Error deleting the user: ", error);
      }
    }

    // Close the modal
    handleDeleteModalClose();
  };

  return (
    <div>
      <h2 className="text-center my-5">User Details</h2>

      {/* Show the "Add User" button */}
      {!showAddForm && (
        <button onClick={() => setShowAddForm(true)}>Add User</button>
      )}

      {/* Show the form fields only when "Add User" button is clicked */}
      {showAddForm && (
        <form onSubmit={isEditing ? handleUpdate : handleAddUser}>
          <div>
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              className="form-control my-3"
              value={newUserData.first_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="middle_name">Middle Name</label>
            <input
              type="text"
              name="middle_name"
              className="form-control my-3"
              value={newUserData.middle_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              className="form-control my-3"
              value={newUserData.last_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email_id">Email</label>
            <input
              type="email"
              name="email_id"
              className="form-control my-3"
              value={newUserData.email_id}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              className="form-control my-3"
              value={newUserData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="date_of_birth">Date of Birth</label>
            <input
              type="date"
              name="date_of_birth"
              className="form-control my-3"
              value={newUserData.date_of_birth}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="gender">Gender</label>
            <input
              type="text"
              name="gender"
              className="form-control my-3"
              value={newUserData.gender}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="home_address">Home Address</label>
            <input
              type="text"
              name="home_address"
              className="form-control my-3"
              value={newUserData.home_address}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              name="phone"
              className="form-control my-3"
              value={newUserData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="role">Role</label>
            <input
              type="text"
              name="role"
              className="form-control my-3"
              value={newUserData.role}
              onChange={handleInputChange}
              required
            />
          </div>
          <button className="btn btn-success btn-block my-3" type="submit">
            {isEditing ? "Update User" : "Add User"}
          </button>
          {isEditing && (
            <button
              className="btn btn-success btn-block my-3"
              onClick={handleCancelEdit}
            >
              Cancel Edit
            </button>
          )}
          {!isEditing && (
            <button
              className="btn btn-success btn-block my-3"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          )}
        </form>
      )}

{users.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_account_id}>
                <td>{`${user.first_name} ${user.middle_name} ${user.last_name}`}</td>
                <td>{user.email_id}</td>
                <td>{user.role}</td>
                <td>
                  <button className="edit-btn" onClick={() => handleEdit(user)}>
                    Edit
                  </button>
                  <button className="delete-btn" onClick={() => handleDeleteModalOpen(user.user_account_id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No users found.</p>
      )}

      {/* Modal for Delete Confirmation */}
      <Modal
        isOpen={showModal}
        onRequestClose={handleDeleteModalClose}
        contentLabel="Delete User Confirmation"
      >
        <h2>Delete User</h2>
        <p>Are you sure you want to delete this user?</p>
        <button onClick={handleDeleteConfirm}>Yes</button>
        <button onClick={handleDeleteModalClose}>No</button>
      </Modal>
    </div>
  );
};

export default UsersCrud;
