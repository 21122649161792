import React from 'react';
import Modal from 'react-modal';


const OrganizationUpdateStatusModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Update Status"
      overlayClassName="Overlay"
    >
      <h2>Confirm Update</h2>
      <p>Are you sure you want to update the status to 'active'?</p>
      <button onClick={onConfirm}>Yes</button>
      <button onClick={onRequestClose}>No</button>
    </Modal>
  );
};

export default OrganizationUpdateStatusModal;
