import React, { useState, useEffect } from 'react';

const PatientForm = ({ initialData, onSubmit, onCancel, isEditing }) => {
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          className="form-control my-3"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="age">Age</label>
        <input
          type="text"
          name="age"
          className="form-control my-3"
          value={formData.age}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="gender">Gender</label>
        <input
          className="form-control my-3"
          type="text"
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="contact">Contact</label>
        <input
          className="form-control my-3"
          type="number"
          min={10}
          name="contact"
          value={formData.contact}
          onChange={handleInputChange}
          required
        />
      </div>
      <button className="btn btn-success btn-block my-3" type="submit">
        {isEditing ? 'Update Patient' : 'Add Patient'}
      </button>
      &nbsp;
      <button className="btn btn-danger btn-block my-3" onClick={onCancel}>
        Cancel
      </button>
    </form>
  );
};

export default PatientForm;
