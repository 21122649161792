import axios from 'axios';
import { apiUrl } from '../../../environment';

// Fetch all entities parameters
export const fetchAllEntitiesParameters = async () => {
  try {
    const response = await axios.get(`${apiUrl}/entities-parameters`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all entities parameters:", error);
    return [];
  }
};

// Fetch entities parameter by ID
export const fetchEntitiesParameterById = async (entitiesParameterId) => {
  try {
    const response = await axios.get(`${apiUrl}/entities-parameters/${entitiesParameterId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching entities parameter by ID:", error);
    return null;
  }
};

// Fetch entities parameter by sub-procedure entity ID
export const fetchEntitiesParameterBySubProcEntId = async (subProcedureEntityId) => {
  try {
    const response = await axios.get(`${apiUrl}/entities-parameters/sub-procedure-entity/${subProcedureEntityId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching entities parameter by sub-procedure entity ID:", error);
    return null;
  }
};

// Create a new entities parameter
export const createEntitiesParameter = async (entitiesParameterData) => {
  try {
    const response = await axios.post(`${apiUrl}/entities-parameters/create`, entitiesParameterData);
    return response.data;
  } catch (error) {
    console.error("Error creating entities parameter:", error);
    return null;
  }
};

// Update an entities parameter
export const updateEntitiesParameter = async (entitiesParameterId, updatedEntitiesParameterData) => {
  try {
    const response = await axios.put(`${apiUrl}/entities-parameters/${entitiesParameterId}/update`, updatedEntitiesParameterData);
    return response.data;
  } catch (error) {
    console.error("Error updating entities parameter:", error);
    return null;
  }
};

// Delete an entities parameter
export const deleteEntitiesParameter = async (entitiesParameterId) => {
  try {
    const response = await axios.delete(`${apiUrl}/entities-parameters/${entitiesParameterId}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error deleting entities parameter:", error);
    return null;
  }
};
