import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../environment';
import { Link } from 'react-router-dom';
import Header from '../header/Header';

const OrganizationApprovals = () => {
    const [approvals, setApprovals] = useState([]);

    useEffect(() => {
        const fetchApprovals = async () => {
            try {
                const response = await fetch(`${apiUrl}/organization-approval`);
                const data = await response.json();
                console.log(data);
              
                // Access the nested approvalData array
                if (Array.isArray(data.organizationApprovals?.approvalData)) {
                  setApprovals(data.organizationApprovals.approvalData);
                } else {
                  console.error('Approvals data is not an array:', data);
                }
              } catch (error) {
                console.error('Error fetching approvals:', error);
              }
              
        };

        fetchApprovals();
    }, []);

    return (
        <>
        <Header />
            <div>
            <h2>Organization Approvals</h2>
            <table className="user-table">
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Organization ID</th>
                        <th>Status</th>
                        <th>Comments</th>
                        <th>Created Time</th>
                        <th>Last Updated Time</th>
                    </tr>
                </thead>
                <tbody>
                    {approvals.map((approval, index) => (
                        <tr key={index}>
                            <td>{approval.user_id}</td>
                            <td>
                                <Link to={`/organization-approval/${approval.organization_approval_id}`}>
                                    {approval.organization_id}
                                </Link>
                            </td>
                            <td>{approval.status}</td>
                            <td>{approval.comments}</td>
                            <td>{new Date(approval?.created_time).toLocaleString()}</td>
                            <td>{new Date(approval?.last_updated_time).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    );
};

export default OrganizationApprovals;
