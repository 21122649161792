import axios from 'axios';
import { apiUrl } from '../../../environment';

export const getAllApprovals = async () => {
  try {
    const response = await axios.get(`${apiUrl}/approvals`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all approvals:', error);
    throw error;
  }
};

export const createApproval = async (approvalData) => {
  try {
    const response = await axios.post(`${apiUrl}/approvals/create`, approvalData);
    return response.data;
  } catch (error) {
    console.error('Error creating approval:', error);
    throw error;
  }
};

export const updateApproval = async (approvalId, approvalData) => {
  try {
    const response = await axios.put(`${apiUrl}/approvals/${approvalId}`, approvalData);
    return response.data;
  } catch (error) {
    console.error(`Error updating approval with ID ${approvalId}:`, error);
    throw error;
  }
};

export const updateApprovalByUserId = async (userId, approvalData) => {
  try {
    const response = await axios.put(`${apiUrl}/approvals/user/${userId}`, approvalData);
    return response.data;
  } catch (error) {
    console.error(`Error updating approval for user ID ${userId}:`, error);
    throw error;
  }
};

export const deleteApproval = async (approvalId) => {
  try {
    const response = await axios.delete(`${apiUrl}/approvals/${approvalId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting approval with ID ${approvalId}:`, error);
    throw error;
  }
};
