import axios from 'axios';
import { apiUrl } from '../../../environment';

// Fetch image
export const fetchImage = async (entityId, imageType) => {
  try {
    const response = await axios.get(`${apiUrl}/images/${entityId}/${imageType}/active`, {
      headers: { "authorization": localStorage.authorization },
      responseType: 'blob'
    });

    if (response.status === 200) {
      return URL.createObjectURL(response.data);
    } else {
      console.error("Failed to fetch image:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching image:", error.message);
    return null;
  }
};

// Upload image
export const uploadImage = async (file, userAccountId, imageType) => {
  console.log(file);
  console.log(userAccountId);
  console.log(imageType);
  const form = new FormData();
  form.append("myImage", file);  
  form.append("user_account_id", userAccountId);
  form.append("image_type", imageType);
  form.append("image_format", file.type);
  form.append("image_status", "active"); // Status is "active" by default

  try {
    const response = await axios.post(`${apiUrl}/images/create`, form, {
      headers: {
        "authorization": localStorage.authorization,
      },
    });

    if (response.status === 200 || response.status === 201) {
      console.log("Image uploaded successfully:", response.data);
      return response.data; // Return the response data if needed
    } else {
      console.error("Failed to upload image:", response.status);
    }
  } catch (err) {
    console.error("Error uploading image:", err.message);
  }
};

// export const uploadImage = async (file, entityId, imageType) => {
//   const form = new FormData();
//   form.append("myImage", file);
//   form.append("user_account_id", entityId);
//   form.append("image_type", imageType);
//   form.append("image_status", "active");

//   try {
//     const response = await axios.post(`${apiUrl}/images/create`, form, {
//       headers: { "authorization": localStorage.authorization }
//     });

//     if (response.status === 200) {
//       console.log("Image uploaded successfully:", response.data);
//     } else {
//       console.error("Failed to upload image:", response.status);
//     }
//   } catch (err) {
//     console.error("Error uploading image:", err.message);
//   }
// };

/**
 * Function to upload multiple images with dynamic field name and image type
 * @param {string} entityId - The entity ID associated with the image
 * @param {Array} selectedFiles - Array of File objects to upload
 * @param {string} fieldName - Field name for FormData (e.g., 'proc_pic')
 * @param {string} imageType - Type of image (e.g., 'Procedure_pic')
 */
/**
 * Function to upload multiple images.
 * @param {string} entityId - The ID associated with the entity (procedure ID in this case)
 * @param {File[]} selectedFiles - Array of files to be uploaded
 * @param {string} fieldName - The field name for the file input
 * @param {string} imageType - Type of image (e.g., 'Procedure_pic')
 */
export const uploadMultipleImages = async (entityId, selectedFiles, fieldName, imageType) => {
  try {
    console.log(selectedFiles);
    const formData = new FormData();
    formData.append("user_account_id", entityId);

    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        formData.append(fieldName, file);
        formData.append("image_format", file.type);
      });
    } else {
      console.error("selectedFiles is not an array or is empty");
      return; // Exit function if selectedFiles is not an array or is empty
    }

    formData.append("image_type", imageType);
    formData.append("image_status", "active");

    const response = await axios.post(`${apiUrl}/images/create-multiple/${fieldName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'authorization': localStorage.authorization // Adjust as per your authorization mechanism
      }
    });

    if (response.data) {
      console.log("Images uploaded successfully:", response.data);
      // Handle success if needed
    } else {
      console.error("Failed to upload images:", response.status);
      throw new Error(`Failed to upload images: ${response.status}`);
    }
  } catch (error) {
    console.error("Error uploading images:", error.message);
    throw error;
  }
};

/**
 * Function to fetch multiple images based on user account ID, image type, and image status
 * @param {string} userAccountId - The ID associated with the user account (procedure ID in this case)
 * @param {string} imageType - Type of image (e.g., 'Procedure_pic')
 * @param {string} imageStatus - Status of the image (e.g., 'active')
 */
export const fetchMultipleImages = async (userAccountId, imageType, imageStatus) => {
  try {
    const response = await axios.get(`${apiUrl}/images/${userAccountId}/${imageType}/${imageStatus}/multiple`, {
      headers: {
        'authorization': localStorage.authorization // Adjust as per your authorization mechanism
      }
    });

    if (response.status === 200) {
      console.log("Images fetched successfully:", response.data);
      return response.data; // Return fetched data if needed
    } else {
      console.info("Failed to fetch images:", response.status);
      // throw new Error(`Failed to fetch images: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching images:", error.message);
    throw error;
  }
};