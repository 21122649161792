import React, { useEffect, useState } from 'react';
import { getOrganizationMembers } from './services/OrganizationService';
import { getUserDetails } from '../users/services/UserService';

const OrganizationMembers = () => {
  const [userDetailsList, setUserDetailsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const organization_id = localStorage.getItem('organization_id');
        const authToken = localStorage.getItem('authorization');
        
        if (!organization_id || !authToken) {
          console.error('Missing organization_id or authorization token in localStorage');
          setLoading(false);
          return;
        }

        const orgUserLinkData = await getOrganizationMembers(organization_id);

        if (!Array.isArray(orgUserLinkData?.organizationUserLinkData) || orgUserLinkData?.organizationUserLinkData?.length === 0) {
          console.error('No user IDs found for the given organization_id');
          setLoading(false);
          return;
        }

        const userDetailsPromises = orgUserLinkData.organizationUserLinkData.map(async (link) => {
          return await getUserDetails(link.user_id, authToken);
        });

        const userDetailsArray = await Promise.all(userDetailsPromises);
        const validUserDetails = userDetailsArray.filter(details => details !== null);
        
        setUserDetailsList(validUserDetails);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {userDetailsList.map((userDetails, index) => (
            <div key={index}>
              <p>Name: {userDetails?.user?.first_name} {userDetails?.user?.last_name} - Account type: {userDetails?.user?.role}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


export default OrganizationMembers;
