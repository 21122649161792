import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Header from '../header/Header';
import { getAllOrganizationUserLinks, updateOrganizationUserLink } from './services/OrganizationService';

const OrganizationMemberRequests = () => {
  const [organizationUserLinks, setOrganizationUserLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchOrganizationUserLinks();
  }, []);

  const fetchOrganizationUserLinks = async () => {
    try {
      const data = await getAllOrganizationUserLinks();
      if (Array.isArray(data.organizationUserLinks)) {
        setOrganizationUserLinks(data.organizationUserLinks);
      } else {
        console.error('Data received is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching organization user links:', error);
    }
  };

  const handleStatusUpdate = async (status) => {
    if (!selectedLink) return;
    console.log('Updating status for:', selectedLink);
    console.log('New status:', status);
    try {
        console.log(selectedLink.organization_user_link_id)
      const updateOrganizationUserLinkResponse = await updateOrganizationUserLink(selectedLink.organization_user_link_id, {
        organization_id: selectedLink.organization_id,
        user_id: selectedLink.user_id,
        status: status
      });
      console.log('Update response:', updateOrganizationUserLinkResponse);
      if(updateOrganizationUserLinkResponse) {
        await fetchOrganizationUserLinks();
        closeModal();
      }
    } catch (error) {
      console.error(`Error ${status}ing organization user link:`, error);
    }
  };

  const openModal = (link) => {
    setSelectedLink(link);
    console.log(link);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedLink(null);
    setShowModal(false);
  };

  return (
    <div>
      <Header />
      <h2>Organization Member Requests</h2>
      <table className="table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Organization Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {organizationUserLinks.map((link) => (
            <tr key={link.organization_user_link_id}>
              <td>{link.user_name}</td>
              <td>{link.org_name}</td>
              <td>{link.status}</td>
              <td>
                <button onClick={() => openModal(link)}>Update status</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for approving/rejecting */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Approve/Reject Request"
      >
        <h2>Approve or Reject Request</h2>
        {selectedLink && (
          <div>
            <p>User: {selectedLink.user_name}</p>
            <p>Organization: {selectedLink.org_name}</p>
            <div>
              <button onClick={() => handleStatusUpdate('active')}>Approve</button>
              <button onClick={() => handleStatusUpdate('rejected')}>Reject</button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OrganizationMemberRequests;
