import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { createOrganizationUserLink } from '../services/OrganizationService';

const OrganizationJoinModel = ({ organizationId, onClose }) => {
  const [reason, setReason] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      
  const organizationUserLinkData = {
    organization_id: organizationId,
    user_id: localStorage.getItem("user_id"),
    status: 'pending',
    account_type: localStorage.getItem("role")
  };

  const response = await createOrganizationUserLink(organizationUserLinkData);

  if (response) {
    onClose();
    navigate(`/dashboard`);
  } else {
    console.error('Failed to create approval');
  }
    } catch (error) {
      console.error('Error creating approval: ', error);
    }
  };

  return (
    <Modal isOpen={true}>
      <h2>Join Organization</h2>
      <label htmlFor="reason">Reason to Join:</label>
      <input
        type="text"
        id="reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
      <button onClick={handleSubmit}>Submit</button>
      <button onClick={onClose}>Cancel</button>
    </Modal>
  );
};

export default OrganizationJoinModel;
