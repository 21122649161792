import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { apiUrl } from '../../environment';
import Header from '../header/Header';

const EntityParamsEdit = ({onClose, onUpdate, entities_parameters_id}) => {
  const [newEntData, setnewEntData] = useState({
    entities_parameters_name: "",
    entities_parameters_value: "",
    entities_parameters_units: "",  });
  const [sub_procedure__ent_id, setSubEntId] = useState("");
  const [procedure_id, setProcId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch procedure data based on procedure_id
    fetchEntData(entities_parameters_id);
  }, [entities_parameters_id]);

  const fetchEntData = async (entId) => {
    try {
      const entParamsResponse = await fetch(
        `${apiUrl}/entities-parameters/${entId}`
      );

      if (entParamsResponse.ok) {
        const entData = await entParamsResponse.json();
        setSubEntId(entData.entitiesParameter.sub_procedure_entities_id);
        setnewEntData({
            entities_parameters_name: entData.entitiesParameter.name,
            entities_parameters_units: entData.entitiesParameter.units,
            entities_parameters_value: entData.entitiesParameter.value
        });
        try {
            const subProcEntResponse = await fetch(
              `${apiUrl}/sub-procedure-entities/${entData.entitiesParameter.sub_procedure_entities_id}`
            );
      
            if (subProcEntResponse.ok) {
              const subProcedureEntData = await subProcEntResponse.json();
              try {
                const subProcResponse = await fetch(
                  `${apiUrl}/sub-procedures/${subProcedureEntData.subProcedureEntity.sub_procedure_id}`
                );
      
                if (subProcResponse.ok) {
                  const subProcedureData = await subProcResponse.json();
                  setProcId(subProcedureData.subProcedureRecord.procedure_id);
                } else {
                  console.error("Failed to fetch procedure data");
                }
              } catch (error) {
                console.error("Error fetching procedure data:", error);
              }
            } else {
              console.error("Failed to fetch procedure data");
            }
          } catch (error) {
            console.error("Error fetching procedure data:", error);
          }
      } else {
        console.error("Failed to fetch procedure data");
      }
    } catch (error) {
      console.error("Error fetching procedure data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the local state only if the value has changed
    if (newEntData[name] !== value) {
      setnewEntData({
        ...newEntData,
        [name]: value,
      });
    }
  };


  const updateEntRecord = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apiUrl}/entities-parameters/${entities_parameters_id}/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sub_procedure_entities_id: sub_procedure__ent_id,
            name: newEntData.entities_parameters_name,
            units: newEntData.entities_parameters_units,
            value: newEntData.entities_parameters_value
          }),
        }
      );

      if (response.ok) {
        onUpdate();
        onClose();
      } else {
        console.error("Failed to update procedure record");
      }
    } catch (error) {
      console.error("Error updating procedure record:", error);
    }
}
  return (
    <div>
    <form onSubmit={updateEntRecord}>
      <div>
        <label htmlFor="entities_parameters_name">Entities name</label>
        <input
          type="text"
          name="entities_parameters_name"
          className="form-control my-3"
          value={newEntData.entities_parameters_name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="entities_parameters_units">Entities units</label>
        <input
          type="text"
          name="entities_parameters_units"
          className="form-control my-3"
          value={newEntData.entities_parameters_units}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="entities_parameters_value">Entities value</label>
        <input
          type="text"
          name="entities_parameters_value"
          className="form-control my-3"
          value={newEntData.entities_parameters_value}
          onChange={handleInputChange}
          required
        />
      </div>
      <button className="btn btn-success btn-block my-3" type="submit">
        Update Entities Parameters{" "}
      </button>{" "}
      &nbsp;
      <button
        className="btn btn-success btn-block my-3"
        onClick={onClose}
      >
        Cancel
      </button>
    </form>
  </div>
  )
}

export default EntityParamsEdit
