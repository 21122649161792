import axios from 'axios';
import { apiUrl } from '../../../environment';

// Fetch all sub-procedure records
export const fetchAllSubProcedureRecords = async () => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedures`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all sub-procedure records:", error);
    return [];
  }
};

// Fetch sub-procedure record by ID
export const fetchSubProcedureRecordById = async (subProcedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedures/${subProcedureId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching sub-procedure record by ID:", error);
    return null;
  }
};

// Fetch sub-procedure records by procedure ID
export const fetchSubProcedureRecordsByProcedureId = async (procedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedures/procedure/${procedureId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching sub-procedure records by procedure ID:", error);
    return [];
  }
};

// Create a new sub-procedure record
export const createSubProcedureRecord = async (subProcedureData) => {
  try {
    const response = await axios.post(`${apiUrl}/sub-procedures/create`, subProcedureData);
    return response.data;
  } catch (error) {
    console.error("Error creating sub-procedure record:", error);
    return null;
  }
};

// Update a sub-procedure record
export const updateSubProcedureRecord = async (subProcedureId, subProcedureData) => {
  try {
    const response = await axios.put(`${apiUrl}/sub-procedures/${subProcedureId}/update`, subProcedureData);
    return response.data;
  } catch (error) {
    console.error("Error updating sub-procedure record:", error);
    return null;
  }
};

// Delete a sub-procedure record
export const deleteSubProcedureRecord = async (subProcedureId) => {
  try {
    const response = await axios.put(`${apiUrl}/sub-procedures/${subProcedureId}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error deleting sub-procedure record:", error);
    return null;
  }
};
