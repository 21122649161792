import axios from 'axios';
import { apiUrl } from '../../../environment';

export const getAllPatient = async () => {
  try {
    const response = await axios.get(`${apiUrl}/patient`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all patient:', error);
    throw error;
  }
};

export const getPatientById = async (patient_id) => {
  try {
    const response = await axios.get(`${apiUrl}/patient/${patient_id}`);
    console.log("API Response for patient ID ", patient_id, ": ", response);
    return response.data;
  } catch (error) {
    console.error(`Error fetching patient with ID ${patient_id}:`, error);
    throw error;
  }
};


export const createPatient = async (patientData) => {
  try {
    console.log(patientData);
    const response = await axios.post(`${apiUrl}/patient/create`, patientData);
    return response.data;
  } catch (error) {
    console.error('Error creating patient:', error);
    throw error;
  }
};

export const updatePatient = async (patientId, patientData) => {
  try {
    const response = await axios.put(`${apiUrl}/patient/${patientId}`, patientData);
    return response.data;
  } catch (error) {
    console.error(`Error updating patient with ID ${patientId}:`, error);
    throw error;
  }
};

export const deletePatient = async (patientId) => {
  try {
    const response = await axios.delete(`${apiUrl}/patient/${patientId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting patient with ID ${patientId}:`, error);
    throw error;
  }
};
