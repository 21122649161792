import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchOrgApprovalDetails, updateOrgApprovalStatus, updateOrganization } from './services/OrganizationService';
import Header from '../header/Header';
import OrganizationUpdateStatusModal from './modals/OrganizationUpdateStatusModal';

const OrganizationApprovalDetails = () => {
    const [approval, setApproval] = useState(null);
    const [userData, setUserData] = useState(null);
    const [orgData, setOrgData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { organization_approval_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrgApprovalDetails(organization_approval_id)
            .then(data => {
                if (data) {
                    setApproval(data.approvalData);
                    setOrgData(data.organizationData);
                    setUserData(data.userData);
                }
            })
            .catch(error => console.error('Error fetching approval details:', error));
    }, [organization_approval_id]);

    const handleUpdate = async () => {
        console.log(orgData[0].organization_id);

        try {
            // Update organization status to active
            const organization_id = orgData[0].organization_id;
            const updatedData = { status: 'active' };

            const orgUpdateSuccess = await updateOrganization(organization_id, updatedData);
            if (!orgUpdateSuccess) {
                console.error('Failed to update organization status.');
                return;
            }

            // Update organization approval status to active
            const approvalUpdateSuccess = await updateOrgApprovalStatus(organization_approval_id, 'active');
            if (approvalUpdateSuccess) {
                navigate('/organization-approvals');
            } else {
                console.error('Failed to update approval status.');
            }
        } catch (error) {
            console.error('Error updating approval:', error);
        }
    };


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const confirmUpdate = () => {
        handleUpdate();
        closeModal();
    };

    return (
        <>
            <Header />
            <div className='row pt-2'>
                <OrganizationUpdateStatusModal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    onConfirm={confirmUpdate}
                />
                <div className='col-md-6 col-12'>
                    <div>
                        <h2>Organization Approval Details</h2>
                        {approval ? (
                            <div>
                                <h3>Approval Information</h3>
                                <p>Status: {approval[0]?.status}</p>
                                <p>Comments: {approval[0]?.comments}</p>
                                <p>Created Time: {new Date(approval[0]?.created_time).toLocaleString()}</p>
                                <p>Last Updated Time: {new Date(approval[0]?.last_updated_time).toLocaleString()}</p>
                            </div>
                        ) : (
                            <p>Loading approval details...</p>
                        )}

                        {userData ? (
                            <div>
                                <h3>User Information</h3>
                                <p>{userData[0]?.first_name}</p>
                                <p>{userData[0]?.email_id}</p>
                                <p>{userData[0]?.role}</p>
                                {/* Add more user details as needed */}
                            </div>
                        ) : (
                            <p>Loading user details...</p>
                        )}

                        {orgData ? (
                            <div>
                                <h3>Organization Information</h3>
                                <p>{orgData[0]?.name}</p>
                                <p>{orgData[0]?.description}</p>
                                <p>
                                    <a href={`mailto:${orgData[0]?.contact_email}`}>
                                        {orgData[0]?.contact_email}
                                    </a>
                                </p>
                                <p>
                                    <a href={orgData[0]?.website} target="_blank" rel="noopener noreferrer">
                                        {orgData[0]?.website}
                                    </a>
                                </p>

                                {/* Add more organization details as needed */}
                            </div>
                        ) : (
                            <p>Loading organization details...</p>
                        )}
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <button onClick={openModal}>Update Status</button>
                </div>
            </div>
        </>
    );
};

export default OrganizationApprovalDetails;
