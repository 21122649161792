import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure to set this to your app's root element

const FormModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={{
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'auto',
            maxHeight: '80vh',
            maxWidth: '80%',
            padding: '20px'
          }
      }}
    >
      <button onClick={onRequestClose}>Close</button>
      {children}
    </Modal>
  );
};

export default FormModal;
