import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import {
  fetchOrganizationData,
  updateOrganization,
  addOrganization,
  deleteOrganization,
  getOrganizationUserLinkByUserId,
} from './services/OrganizationService';
import { fetchImage, uploadImage } from '../images/services/ImageService';
import FormModal from "../../modals/FormModal"; // Adjust the import path as necessary
import OrganizationForm from "./OrganizationForm"; // Adjust the import path as necessary

const fetchOrganizations = async () => {
  try {
    const organizations = await fetchOrganizationData();
    return organizations; // Return the list of organizations
  } catch (error) {
    console.error("Error fetching organization data: ", error);
    return []; // Return an empty array in case of an error
  }
};

const Organization = () => {
  const [organizations, setOrganizations] = useState([]);
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newOrganizationData, setNewOrganizationData] = useState({
    name: "",
    contact_email: "",
    website: "",
    image: null,
    description: "",
    personal: "",
    comments: ""
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editOrganizationId, setEditOrganizationId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteOrganizationId, setDeleteOrganizationId] = useState(null);

  const navigate = useNavigate();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditing(false);
    setEditOrganizationId(null);
    setNewOrganizationData({
      name: "",
      contact_email: "",
      website: "",
      image: null,
      description: "",
      personal: "",
      comments: ""
    });
  };

  const handleEdit = (organization) => {
    setEditOrganizationId(organization.organization_id);
    setNewOrganizationData({
      name: organization.name,
      contact_email: organization.contact_email,
      website: organization.website,
      image: organization.image,
      description: organization.description,
      personal: organization.personal,
      comments: organization.comments
    });
    setIsEditing(true);
    openModal();
  };

  useEffect(() => {
    const fetchUserOrganizations = async () => {
      const userId = localStorage.getItem("user_id");
      const role = localStorage.getItem("role");

      if (role !== "admin" && userId) {
        const response = await getOrganizationUserLinkByUserId(userId);
        const userOrgs = response.organizationUserLinkData || [];
        setUserOrganizations(userOrgs);
      }
    };

    fetchOrganizations().then((orgData) => {
      setOrganizations(orgData);
    });

    fetchUserOrganizations();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewOrganizationData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewOrganizationData((prevData) => ({
        ...prevData,
        image: file,
      }));
    }
  };

  const handlePubPrivateChange = (event) => {
    setNewOrganizationData((prevData) => ({
      ...prevData,
      personal: event.target.value
    }));
  };

  const handleCommentsChange = (event) => {
    setNewOrganizationData((prevData) => ({
      ...prevData,
      comments: event.target.value
    }));
  };

  const uploadOrgImage = async (file, organizationId) => {
    const response = await uploadImage(file, organizationId, 'organization_logo');
    if (!response.ok) {
      console.error("Failed to upload image:", response.status);
    }
  };

  const handleAddOrganization = async (event) => {
    event.preventDefault();
    try {
      const role = localStorage.getItem("role");
      const createdOrg = await addOrganization(newOrganizationData, role, newOrganizationData.personal);
      await uploadOrgImage(newOrganizationData.image, createdOrg.organization_id);
      const updatedOrganizations = await fetchOrganizations();
      setOrganizations(updatedOrganizations);
      setModalIsOpen(false);
      closeModal();
      fetchOrganizations();
    } catch (error) {
      console.error("Error adding a new organization: ", error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await updateOrganization(editOrganizationId, newOrganizationData);
      await uploadOrgImage(newOrganizationData.image, editOrganizationId);
      const updatedOrganizations = await fetchOrganizations();
      setOrganizations(updatedOrganizations);
      setModalIsOpen(false);
      closeModal();
    } catch (error) {
      console.error("Error updating the organization: ", error);
    }
  };

  const handleDeleteModalOpen = (organization_id) => {
    setShowModal(true);
    setDeleteOrganizationId(organization_id);
  };

  const handleDeleteModalClose = () => {
    setShowModal(false);
    setDeleteOrganizationId(null);
  };

  const handleDelete = async () => {
    if (deleteOrganizationId) {
      try {
        const success = await deleteOrganization(deleteOrganizationId);
        if (success) {
          const orgData = await fetchOrganizations();
          setOrganizations(orgData);
        } else {
          console.error("Failed to delete the organization.");
        }
      } catch (error) {
        console.error("Error deleting the organization: ", error);
      }
    }
    handleDeleteModalClose();
  };

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const isUserMember = (organization_id) => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      return true;
    }
    return userOrganizations?.length > 0 && userOrganizations.some((org) => org.organization_id === organization_id);
  };

  return (
    <div>
      <Header />
      <h2 className="text-center my-5">Organization Details</h2>
      <button onClick={openModal}>Add Organization</button>

      <FormModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={isEditing ? "Edit Organization" : "Add Organization"}
      >
        <OrganizationForm
          newOrganizationData={newOrganizationData}
          isEditing={isEditing}
          handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          handlePubPrivateChange={handlePubPrivateChange}
          handleCommentsChange={handleCommentsChange}
          handleAddOrganization={handleAddOrganization}
          handleUpdate={handleUpdate}
          closeModal={closeModal}
        />
      </FormModal>

      {organizations.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Email</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {organizations
              .filter((org) => isUserMember(org.organization_id))
              .map((org) => (
                <tr key={org.organization_id}>
                  <td onClick={() => navigate(`organization/${org.organization_id}`)}>{org.name}</td>
                  <td>{org.contact_email}</td>
                  <td>{org.description}</td>
                  <td>
                    <button onClick={() => handleEdit(org)}>Edit</button> &nbsp;
                    <button onClick={() => handleDeleteModalOpen(org.organization_id)}>Delete</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p>No organizations found.</p>
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={handleDeleteModalClose}
        contentLabel="Confirm Delete"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this organization?</p>
        <button onClick={handleDelete}>Yes</button>
        <button onClick={handleDeleteModalClose}>No</button>
      </Modal>
    </div>
  );
};

export { Organization, fetchOrganizations };
