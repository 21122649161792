import axios from 'axios';
import { apiUrl } from '../../../environment';


// Function to get all shared access records
export const getAllSharedAccessRecords = async () => {
  try {
    const response = await axios.get(`${apiUrl}/shared-access`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error('An error occurred while fetching all shared access records.');
  }
};

// Function to get a shared access record by shared_access_id
export const getSharedAccessById = async (shared_access_id) => {
  try {
    const response = await axios.get(`${apiUrl}/shared-access/${shared_access_id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while fetching shared access record with ID: ${shared_access_id}`);
  }
};

// Function to create a new shared access record
export const createSharedAccessEntry = async (sharedAccessData) => {
  try {
    const response = await axios.post(`${apiUrl}/shared-access/create`, sharedAccessData, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error('An error occurred while creating a new shared access record.');
  }
};

// Function to update a shared access record
export const updateSharedAccess = async (shared_access_id, sharedAccessData) => {
  try {
    const response = await axios.put(`${apiUrl}/shared-access/${shared_access_id}/update`, sharedAccessData, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while updating shared access record with ID: ${shared_access_id}`);
  }
};

// Function to delete a shared access record
export const deleteSharedAccess = async (shared_access_id) => {
  try {
    const response = await axios.delete(`${apiUrl}/shared-access/${shared_access_id}/delete`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while deleting shared access record with ID: ${shared_access_id}`);
  }
};

// Function to find shared access records by user_id
export const findSharedAccessByUserId = async (user_id) => {
  try {
    const response = await axios.get(`${apiUrl}/shared-access/user/${user_id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while fetching shared access records for user with ID: ${user_id}`);
  }
};

export const findSharedAccessByProcId = async (procedure_id) => {
  try {
    const response = await axios.get(`${apiUrl}/shared-access/procedure/${procedure_id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while fetching shared access records for shared access with ID: ${procedure_id}`);
  }
};

export const findSharedAccessByOrgId = async (organization_id) => {
  try {
    const response = await axios.get(`${apiUrl}/shared-access/organization/${organization_id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error(`An error occurred while fetching shared access records for procedure with ID: ${organization_id}`);
  }
};