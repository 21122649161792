import React from "react";
import DocViewer from 'react-doc-viewer';

const ImageView = ({ imageUrl, imageFormat }) => {
  // Render different components based on the file format
  const renderContent = () => {
    if (imageFormat === "application/pdf") {
      return (
        <iframe className={imageFormat} width="100%" height="600" frameborder="0" src={imageUrl}></iframe>
      );
    } else if (imageFormat === "application/msword") {
        // return <DocViewer documents={[{ uri: imageUrl, fileType: 'docx' }]} />;
    } else if ((imageFormat === "image/png", "image/jpeg")) {
      return <div className="row">
        <div className="col-12 col-md-6">
        <img className="img-responsive" src={imageUrl} alt="Uploaded Image" />
        </div>
      </div>;
    } else {
      return <p>Unsupported file format.</p>;
    }
  };

  return <div className="image-view">{renderContent()}</div>;
};

export default ImageView;
