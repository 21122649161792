import React, { useEffect, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import Header from "../../header/Header";
import { apiUrl } from "../../../environment";

const UserDetails = () => {
  const [user, setUser] = useState({});
  const { user_id } = useParams();
  const [imageSrc, setImageSrc] = useState("");
  const [profile_info_data, setProfileInfo] = useState({});
  const [ profile_info_id, setProfileInfoId ] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await fetch(`${apiUrl}/users/${user_id}`, {
          method: "GET",
          headers: { "authorization": localStorage.authorization },
        });
        if (userResponse.ok) {
          const userData = await userResponse.json();
          setUser(userData);
          try {
            const userAccountId = user_id;
            const image_type = "certificate_pic";
            const image_status = 'pending'
            const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}`,
              {
                method: "GET",
                headers: { "authorization": localStorage.authorization },
              }
            );
            if (imageResponse.ok) {
              const blob = await imageResponse.blob();
              setImageSrc(URL.createObjectURL(blob));
              try {
                const user_account_id = user_id;
                const profileEntriesResponse = await fetch(`${apiUrl}/profile-entries/${user_account_id}/details`);
                const data = await profileEntriesResponse.json();
                if (profileEntriesResponse.ok) {
                  try {
                    const profile_entry_id = data.profile_entry_data.profile_entry_id;
                    const profileInfoResponse = await fetch(`${apiUrl}/profile-info/${profile_entry_id}/details`);
                    
                    if (profileInfoResponse.ok) {
                      const profileInfoData = await profileInfoResponse.json();
                      setProfileInfoId(profileInfoData.ProfileInfo.profile_info_id);
                      setProfileInfo(profileInfoData.ProfileInfo);
                    }
                  } catch (error) {
                    console.error("Error fetching user work exp:", error);
                  }
                  
                } else {
                  console.error("Failed to fetch profile:", profileEntriesResponse.status);
                }
              } catch (error) {
                console.error("Error fetching user data:", error);
              }
            } else {
              console.error("Failed to fetch image:", imageResponse.status);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        } else {
          console.error("Error fetching user data:", userResponse.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [user_id]);

  const updateUserInfo = async (actionType) => {
    try {
      const status = actionType === "approved" ? "approved" : "rejected";

      const response = await fetch(`${apiUrl}/users/${user_id}/update`, {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
                  body: JSON.stringify({
                    status: status
                  }),
      });

    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const updateWorkExp = async (actionType) => {
    try {
      const status = actionType === "approved" ? "approved" : "rejected";

      const response = await fetch(`${apiUrl}/profile-info/${profile_info_id}/update`, {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
                  body: JSON.stringify({
                    status: status
                  }),
      });

    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const updateApprovalRequest = async (actionType) => {
    try {
      const status = actionType === "approved" ? "approved" : "rejected";
      const response = await fetch(`${apiUrl}/approvals/${user_id}/user`, {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
                  body: JSON.stringify({
                    status: status
                  }),
      });

    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = 'certificate_image.png'; // Set the desired filename here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
     <Header/>
      <h2>User Details</h2>
      <p>First Name: {user?.user?.first_name}</p>
      <p>Last Name: {user?.user?.last_name}</p>
      <p>Gender: {user?.user?.gender}</p>
      <p>Role: {user?.user?.role}</p>
      <p>Email: {user?.user?.email_id}</p> <button className="btn btn-primary ml-3" onClick={() => updateUserInfo("approved")}>
        Approve the user info
      </button> <button className="btn btn-danger ml-3" onClick={() => updateUserInfo("reject")}>
        Reject the user info
      </button>
      {imageSrc && (
        <div>
            <h5 className="pt-4">Work Experience</h5>
            <img className="pb-3" src={imageSrc} alt="Uploaded Image" height={150} width={150} />
            <br></br>
              <button className='btn bg-primary' onClick={handleDownload}>Download Image</button>
        </div>
      )}
      <p>organization : {profile_info_data?.organization}</p>
      <p>location : {profile_info_data?.location}</p>
      <p>title : {profile_info_data?.title}</p>
      {/* Add more user details here */}
      <button className="btn btn-primary ml-3 mb-2" onClick={() => updateWorkExp("approved")}>
        Approve the Work Experience
      </button> &nbsp;
      <button className="btn btn-danger ml-3 mb-2" onClick={() => updateWorkExp("reject")}>
        Reject the Work Experience
      </button> &nbsp;
      <p className="pt-3">Update the approval status for the user by clicking approve or reject</p>
      <button className="btn btn-primary ml-3 mb-2" onClick={() => updateApprovalRequest("approved")}>
        Approve the User
      </button> &nbsp;
      <button className="btn btn-danger ml-3 mb-2" onClick={() => updateApprovalRequest("reject")}>
        Reject the User
      </button>
      <i className="fas fa-bell"></i>
        <span className="badge badge-light">2</span>

    </div>
  );
};

export default UserDetails;
