import React, { Fragment, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

// components
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Profile from "./components/users/component/Profile";
import Notifications from "./components/notifications/Notifications";
import UserDetails from "./components/users/component/UserDetails";
import PatientDetails from "./components/patients/PatientDetails";
import Home from "./components/dashboard/Home";
import SingInConfirmation from "./components/auth/SingInConfirmation";
import ResetPassword from "./components/auth/ResetPassword";
import PatientCrud from "./components/patients/Patient-crud";
import { Organization } from "./components/organizations/Organization";
import ProcedureDetails from "./components/procedures/ProcedureDetails";
import ProcedureCreate from "./components/procedures/ProcedureCreate";
import ProcedureEdit from "./components/procedures/ProcedureEdit";
import { apiUrl } from "./environment";
import CreateSubProcedure from "./components/sub-procedures/CreateSubProcedure";
import SubProcedureEntityCreate from "./components/sub-procedure-entities/SubProcedureEntityCreate";
import EntityParamsCreate from "./components/entity-parameters/EntityParamsCreate";
import EditSubProcedure from "./components/sub-procedures/EditSubProcedure";
import SubProcedureEntityEdit from "./components/sub-procedure-entities/SubProcedureEntityEdit";
import EntityParamsEdit from "./components/entity-parameters/EntityParamsEdit";
import SubProcedureDetails from "./components/sub-procedures/SubProcedureDetails";
import SubProcedureEntityDetails from "./components/sub-procedure-entities/SubProcedureEntityDetails";
import Procedures from "./components/procedures/Procedures";
import OrganizationApprovals from "./components/organizations/OrganizationApprovals";
import OrganizationApprovalDetails from "./components/organizations/OrganizationApprovalDetails";
import OrganizationDetails from "./components/organizations/OrganizationDetails";
import OrganizationMemberRequests from "./components/organizations/OrganizationMemberRequests";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const checkAuthenticated = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "authorization": localStorage.authorization },
        keepalive: true,
      });
      const parseRes = await response.json();
      setIsAuthenticated(parseRes); // Set isAuthenticated based on the verification result
      setIsLoading(false); // Set loading to false once the authentication check is complete
    } catch (err) {
      localStorage.clear();
      console.error(err.message);
      setIsAuthenticated(false); // Set isAuthenticated to false in case of an error
      setIsLoading(false); // Set loading to false in case of an error
    }
  };

  useEffect(() => {
    checkAuthenticated();
    if (window.location.pathname === "/") {
      navigate("dashboard");
    }
  }, []);

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  // Render loading until the authentication check is complete
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <div className="container">
        <Routes>
          {/* Default Route */}
          <Route path="/home" element={<Home />} />
          {/* Public Routes */}
          <Route
            path="/home"
            element={
              !isAuthenticated ? (
                <Home setAuth={setAuth} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/login"
            element={
              !isAuthenticated ? (
                <Login setAuth={setAuth} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/confirm"
            element={
              !isAuthenticated ? (
                <SingInConfirmation setAuth={setAuth} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/forgot-password"
            element={
              !isAuthenticated ? (
                <ResetPassword setAuth={setAuth} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/register"
            element={
              !isAuthenticated ? (
                <Register setAuth={setAuth} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          {/* Protected Route */}
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <Dashboard setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <Profile setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/notifications"
            element={
              isAuthenticated ? (
                <Notifications setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/users/:user_id"
            element={
              isAuthenticated ? (
                <UserDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/patient/:patient_id"
            element={
              isAuthenticated ? (
                <PatientDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/patients"
            element={
              isAuthenticated ? (
                <PatientCrud setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/organizations"
            element={
              isAuthenticated ? (
                <Organization setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/procedures/:organization_id"
            element={
              isAuthenticated ? (
                <Procedures setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/procedure/:procedure_id"
            element={
              isAuthenticated ? (
                <ProcedureDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/sub-procedure/:sub_procedure_id"
            element={
              isAuthenticated ? (
                <SubProcedureDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/sub-procedure-entities/:sub_procedure_entities_id"
            element={
              isAuthenticated ? (
                <SubProcedureEntityDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/procedure/create"
            element={
              isAuthenticated ? (
                <ProcedureCreate setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/procedure/:procedure_id/edit"
            element={
              isAuthenticated ? (
                <ProcedureEdit setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/procedure/:procedure_id/add-sub"
            element={
              isAuthenticated ? (
                <CreateSubProcedure setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/sub-procedure/:sub_procedure_id/add-sub-ent"
            element={
              isAuthenticated ? (
                <SubProcedureEntityCreate setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />

          <Route
            path="/sub-procedure-ent/:sub_procedure_entity_id/entity-params"
            element={
              isAuthenticated ? (
                <EntityParamsCreate setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/member-request"
            element={
              isAuthenticated ? (
                <OrganizationMemberRequests setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/sub-procedures/:sub_procedure_id/edit"
            element={
              isAuthenticated ? (
                <EditSubProcedure setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />

          <Route
            path="/sub-procedure-entities/:sub_procedure_entities_id/edit"
            element={
              isAuthenticated ? (
                <SubProcedureEntityEdit setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />

          <Route
            path="/entity-params/:entities_parameters_id/edit"
            element={
              isAuthenticated ? (
                <EntityParamsEdit setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/organization-approvals"
            element={
              isAuthenticated ? (
                <OrganizationApprovals setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/organization-approval/:organization_approval_id"
            element={
              isAuthenticated ? (
                <OrganizationApprovalDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
          <Route
            path="/organizations/organization/:organization_id"
            element={
              isAuthenticated ? (
                <OrganizationDetails setAuth={setAuth} />
              ) : (
                <Navigate to="/home" replace />
              )
            }
          />
        </Routes>
      </div>
    </Fragment>
  );
}

export default App;
