import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userpool from '../../userpool';
import { useNavigate } from 'react-router-dom';
import FormInput from "../forms/FormInput";
import UseForm from "../forms/UseForm";
import { createProfileEntries, createProfileInfo, createUser } from "../users/services/UserService";
import { createApproval } from "../notifications/services/NotificationsService";
import { uploadImage } from "../images/services/ImageService";

const Register = ({ setAuth }) => {
  const navigate = useNavigate();

  const [inputs, handleInputChange, setInputs] = UseForm({
    first_name: "",
    middle_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    role: "",
    email_id: "",
    password: "",
    title: "",
    organization: "",
    description: "",
    start_date: "",
    end_date: "",
    location: "",
    status: "pending",
    certificate_pic: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    first_name: false,
    middle_name: false,
    last_name: false,
    date_of_birth: false,
    gender: false,
    role: false,
    email_id: false,
    password: false,
    title: false,
    organization: false,
    description: false,
    start_date: false,
    end_date: false,
    location: false,
    certificate_pic: false,
  });

  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onBlur = (e) => {
    const { name } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    setTouchedFields({
      first_name: true,
      middle_name: true,
      last_name: true,
      date_of_birth: true,
      gender: true,
      role: true,
      email_id: true,
      password: true,
      title: true,
      organization: true,
      description: true,
      start_date: true,
      end_date: true,
      location: true,
      certificate_pic: true,
    });

    const requiredFields = [
      "first_name",
      "last_name",
      "date_of_birth",
      "gender",
      "role",
      "email_id",
      "password",
      "title",
      "organization",
      "description",
      "start_date",
      "end_date",
      "location",
      "certificate_pic",
    ];

    let isFormValid = true;
    for (const field of requiredFields) {
      if (inputs[field].trim() === "") {
        toast.error(`${field.replace("_", " ")} is required`);
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      console.log("Some properties are missing values.");
      return;
    }

    try {
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: inputs.email_id,
        })
      ];

      const username = inputs.email_id;
      userpool.signUp(username, inputs.password, attributeList, null, async (err, data) => {
        try {
          const userData = {
            first_name: inputs.first_name,
            middle_name: inputs.middle_name,
            last_name: inputs.last_name,
            date_of_birth: inputs.date_of_birth,
            gender: inputs.gender,
            role: inputs.role,
            email_id: inputs.email_id,
            password: inputs.password,
            status: inputs.status,
          };
      
          const registerResponse = await createUser(userData);
          if (!registerResponse) {
            throw new Error("User registration failed.");
          }
      
          const userAccountId = registerResponse.user.user_account_id;
          await createApprovalAndProfileEntries(userAccountId, inputs);
          await createProfileInfoEntry(userAccountId, inputs);
          await uploadUserImage(userAccountId, selectedFile);
      
          setIsWaitingForApproval(true);
          navigate('/confirm');
          toast.success("User registered successfully");
        } catch (error) {
          console.log(error);
          setAuth(false);
          toast.error("User registration failed. Please try again.");
        }
      });
    } catch (err) {
      console.error(err.message);
      toast.error(
        "An error occurred while processing your request. Please check the credentials again!"
      );
    }
  };

  async function createApprovalAndProfileEntries(userAccountId, inputs) {
    const approvalData = { user_id: userAccountId, status: "pending" };
    const entryData = {
      user_account_id: userAccountId,
      name: "Work Experience",
      description: inputs.description,
      category: "work_exp",
    };
  
    const [approvalResponse, profileEntriesResponse] = await Promise.all([
      createApproval(approvalData),
      createProfileEntries(entryData),
    ]);
  
    if (!approvalResponse || !profileEntriesResponse) {
      throw new Error("Approval or profile entry creation failed.");
    }
  }
  
  async function createProfileInfoEntry(userAccountId, inputs) {
    const profileInfoData = {
      user_id: userAccountId,
      title: inputs.title,
      organization: inputs.organization,
      description: inputs.description,
      start_date: inputs.start_date,
      end_date: inputs.end_date,
      location: inputs.location,
      status: "pending",
    };
  
    const profileInfoResponse = await createProfileInfo(profileInfoData);
  
    if (!profileInfoResponse) {
      throw new Error("Profile info creation failed.");
    }
  }
  
  async function uploadUserImage(userAccountId, selectedFile) {
    const imageType = "certificate_pic";
    const imageUploadResponse = await uploadImage(selectedFile, userAccountId, imageType);
 
    if (!imageUploadResponse) {
      throw new Error("Image upload failed.");
    }
 } 

  const handleImageUpload = (file) => {
    if (file) {
      setSelectedFile(file);
      setInputs((prev) => ({
        ...prev,
        certificate_pic: file.name,
      }));
    }
  };

  return (
    <Fragment>
      <h1 className="text-center my-5">Register</h1>
      {isWaitingForApproval ? (
        <div className="text-center text-primary">
          <p>Wait for Approval</p>
        </div>
      ) : (
        <form onSubmit={onSubmitForm}>
          <div className="row">
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md-3">
              <FormInput
                label="First Name"
                name="first_name"
                value={inputs.first_name}
                onChange={handleInputChange}
                onBlur={onBlur}
                required
              />
              {touchedFields.first_name && inputs.first_name.trim() === "" && (
                <span className="text-danger">First name is required</span>
              )}
            </div>
            <div className="col-12 col-md-3">
              <FormInput
                label="Middle Name"
                name="middle_name"
                value={inputs.middle_name}
                onChange={handleInputChange}
                onBlur={onBlur}
              />
            </div>
            <div className="col-12 col-md-3">
              <FormInput
                label="Last Name"
                name="last_name"
                value={inputs.last_name}
                onChange={handleInputChange}
                onBlur={onBlur}
                required
              />
              {touchedFields.last_name && inputs.last_name.trim() === "" && (
                <span className="text-danger">Last name is required</span>
              )}
            </div>
            <div className="col-12 col-md-1"></div>
          </div>
          <div className="row">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Date of Birth"
              name="date_of_birth"
              type="date"
              value={inputs.date_of_birth}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.date_of_birth && inputs.date_of_birth.trim() === "" && (
              <span className="text-danger">Date of birth is required</span>
            )}
          </div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Gender"
              name="gender"
              value={inputs.gender}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.gender && inputs.gender.trim() === "" && (
              <span className="text-danger">Gender is required</span>
            )}
          </div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Role"
              name="role"
              value={inputs.role}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.role && inputs.role.trim() === "" && (
              <span className="text-danger">Role is required</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Email"
              name="email_id"
              type="email"
              value={inputs.email_id}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.email_id && inputs.email_id.trim() === "" && (
              <span className="text-danger">Email is required</span>
            )}
          </div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Title"
              name="title"
              value={inputs.title}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.title && inputs.title.trim() === "" && (
              <span className="text-danger">Title is required</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-3">
            <FormInput
              label="Organization"
              name="organization"
              value={inputs.organization}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.organization && inputs.organization.trim() === "" && (
              <span className="text-danger">Organization is required</span>
            )}
          </div>
          <div className="col-12 col-md-6">
            <FormInput
              label="Description"
              name="description"
              value={inputs.description}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.description && inputs.description.trim() === "" && (
              <span className="text-danger">Description is required</span>
            )}
          </div>
        </div>

        <div className="row pt-2 pb-2">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <FormInput
              label="Select Start Date"
              name="start_date"
              type="date"
              value={inputs.start_date}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.start_date && inputs.start_date.trim() === "" && (
              <span className="text-danger">Start date is required</span>
            )}
          </div>
          <div className="col-md-3">
            <FormInput
              label="Select End Date"
              name="end_date"
              type="date"
              value={inputs.end_date}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.end_date && inputs.end_date.trim() === "" && (
              <span className="text-danger">End date is required</span>
            )}
          </div>
          <div className="col-md-3">
            <FormInput
              label="Location"
              name="location"
              value={inputs.location}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.location && inputs.location.trim() === "" && (
              <span className="text-danger">Location is required</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <FormInput
              label="Password"
              name="password"
              type="password"
              value={inputs.password}
              onChange={handleInputChange}
              onBlur={onBlur}
              required
            />
            {touchedFields.password && inputs.password.trim() === "" && (
              <span className="text-danger">Password is required</span>
            )}
          </div>
          <div className="col-md-5 pt-3">
            <input
              type="file"
              name="certificate_pic"
              placeholder="Upload file"
              className="form-control my-3"
              onBlur={onBlur}
              accept="image/*"
              onChange={(e) => handleImageUpload(e.target.files[0])}
            />
            {touchedFields.certificate_pic && !selectedFile && (
              <span className="text-danger">Certificate pic is required</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4"></div>
          <div className="col-12 col-md-4">
            <button
              className="btn btn-success btn-block mt-5"
              disabled={
                !(
                  touchedFields.first_name && inputs.first_name.trim() !== "" &&
                  touchedFields.middle_name && inputs.middle_name.trim() !== "" &&
                  touchedFields.last_name && inputs.last_name.trim() !== "" &&
                  touchedFields.date_of_birth && inputs.date_of_birth.trim() !== "" &&
                  touchedFields.gender && inputs.gender.trim() !== "" &&
                  touchedFields.role && inputs.role.trim() !== "" &&
                  touchedFields.email_id && inputs.email_id.trim() !== "" &&
                  touchedFields.password && inputs.password.trim() !== ""
                )
              }
            >
              Submit
            </button>
          </div>
        </div>
        </form>
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default Register;
