import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {userLogout} from '../../../auth'
import Header from "../../header/Header";
import { apiUrl } from "../../../environment";

const Profile = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [profileInfo, setProfileInfoData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedUserData, setEditedUserData] = useState({
    first_name: "", 
    last_name: "",
    date_of_birth: "",
    role: "",
    email_id: "",
    gender: "",
    home_address: "",
    phone_number: "",
    start_date: "", 
    end_date: "",
    organization: "",
    title: "",
    location: ""
  });
  const [formattedDateOfBirth, setFormattedDateOfBirth] = useState("");

  const current = new Date().toISOString().split("T")[0];

  const fetchUserDetails = async (userAccountId) => {
    try {
      const response = await fetch(`${apiUrl}/users/${userAccountId}`,
        {
          method: "GET",
          headers: { "authorization": localStorage.authorization },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        const dateOfBirthString = data.user.date_of_birth;
        const dateOfBirth = new Date(dateOfBirthString);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const dob = dateOfBirth.toLocaleDateString("en-US", options);
        setFormattedDateOfBirth(dob);
        const category = 'work_exp'
        try {
          const response = await fetch(`${apiUrl}/profile-entries/${userAccountId}/${category}`,
            {
              method: "GET",
              headers: { "authorization": localStorage.authorization },
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            const profile_entry_id = data.profile_entry_data.profile_entry_id;
            try {
              const response = await fetch(`${apiUrl}/profile-info/${profile_entry_id}/details`,
                {
                  method: "GET",
                  headers: { "authorization": localStorage.authorization },
                }
              );
        
              if (response.ok) {
                const data = await response.json();
                setProfileInfoData(data.ProfileInfo);
              } else {
                console.error("Failed to fetch user details:", response.status);
              }
            } catch (error) {
              console.error("Error fetching user details:", error.message);
            }
          } else {
            console.error("Failed to fetch user details:", response.status);
          }
        } catch (error) {
          console.error("Error fetching user details:", error.message);
        }
      } else {
        console.error("Failed to fetch user details:", response.status);
      }
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };

  const handleEditSubmit = async () => {
    try {
      if (selectedFile) {
        await uploadImage(selectedFile);
      }
      const userAccountId = localStorage.getItem("user_id");
      const response = await fetch(`${apiUrl}/users/${userAccountId}/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "authorization": localStorage.authorization,
          },
          body: JSON.stringify(editedUserData),
        }
      );


      if (response.ok) {
        // Handle success
        console.log("User data updated successfully");
        // Fetch updated user details
        fetchUserDetails(userAccountId);
        setEditMode(false);
      } else {
        console.error("Failed to update user data:", response.status);
      }
    } catch (error) {
      console.error("Error updating user data:", error.message);
    }
  };

  // const handleButtonClick = async () => {
  //   if (selectedFile) {
  //     console.log(selectedFile);
  //     setProfileBtn(true);
  //     await uploadImage(selectedFile);
  //   }
  // };

  const uploadImage = async (file) => {
    console.log(file);
    const form = new FormData();
    form.append("myImage", file);

    // Retrieve user_account_id from localStorage
    const userAccountId = localStorage.getItem("user_id");
    if (userAccountId) {
      form.append("user_account_id", userAccountId);
    }
    form.append("image_type", "profile_pic");
    form.append("image_status", "active");

    try {
      const response = await fetch(`${apiUrl}/images/create`, {
        method: "POST",
        headers: { "authorization": localStorage.authorization },
        body: form,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Image uploaded successfully:", data);
      } else {
        console.error("Failed to upload image:", response.status);
      }
    } catch (err) {
      console.error("Error uploading image:", err.message);
    }
  };

  useEffect(() => {
    const userAccountId = localStorage.getItem("user_id");
    const image_type = 'profile_pic';
    const image_status = 'active'

    const fetchImage = async () => {
      try {
        const response = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}`,
          {
            method: "GET",
            headers: { "authorization": localStorage.authorization },
          }
        );
        console.log(response);
        if (response.ok) {
          const blob = await response.blob();
          setImageSrc(URL.createObjectURL(blob));
        } else {
          console.error("Failed to fetch image:", response.status);
        }
        fetchUserDetails(userAccountId);
      } catch (error) {
        console.error("Error fetching image:", error.message);
      }
    };
    fetchImage();
  }, []);

  const logout = (e) => {
    e.preventDefault();
    try {
      userLogout();
      localStorage.removeItem("authorization");
      localStorage.removeItem("role");
      localStorage.removeItem("user_account_id");
      localStorage.removeItem("allUsers");
      console.clear();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div>
    <Header/>
      {userData && (
        <div>
              <Tabs>
                <TabList>
                  <Tab>Basic Profile</Tab>
                  <Tab>Advance Profile</Tab>
                </TabList>

                <TabPanel>
                  {editMode ? (
            <div>
              <label>
                First Name:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.first_name}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      first_name: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Last Name:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.last_name}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      last_name: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Date of Birth:
                <input
                  className="form-control my-3"
                  type="date"
                  max={current}
                  value={editedUserData.date_of_birth}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      date_of_birth: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Role:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.role}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      role: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Email:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.email_id}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      email_id: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Gender:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.gender}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      gender: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Address:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.home_address}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      home_address: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Phone:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.phone_number}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      phone_number: e.target.value,
                    })
                  }
                />
              </label>
              <input
                id="fileInput"
                type="file"
                name="myImage"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    console.log(e.target.files[0]);
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
              <button
                className="btn btn-primary p-2 my-3"
                onClick={handleEditSubmit}
              >
                Save
              </button>
              <button
                className="btn btn-secondary p-2 my-3"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
            </div>
          ): (
            <div>
               <h2>
                    {userData.user.first_name + " " + userData.user.last_name}
                  </h2>
                  <p>{formattedDateOfBirth}</p>
                  <p>{userData?.user?.role}</p>
                  <p>{userData?.user?.email_id}</p>
                  <p>{userData?.user?.gender}</p>
                  <p>{userData?.user?.home_address}</p>
                  <p>{userData?.user?.phone_number}</p>
            </div>
          )}
      {imageSrc && (
        <img src={imageSrc} alt="Uploaded Image" height={150} width={150} />
      )}
      <br></br>
                  <button
                    className="btn btn-primary p-2"
                    onClick={() => {
                      // Initialize the editedUserData state when entering edit mode
                      setEditedUserData({
                        first_name: userData.user.first_name,
                        last_name: userData.user.last_name,
                        date_of_birth: userData.user.date_of_birth,
                        role: userData.user.role,
                        email_id: userData.email_id,
                        gender: userData.gender,
                        home_address: userData.home_address,
                        phone_number: userData.phone_number
                      });
                      setEditMode(true);
                    }}
                  >
                       Edit Profile
                  </button>
                </TabPanel>
                <TabPanel>
                {editMode ? (
            <div>
              <label>
                Start Date:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.start_date}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      start_date: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                End Date:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.end_date}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      end_date: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Location:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.location}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      location: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Organization:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.organization}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      organization: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Title:
                <input
                  className="form-control my-3"
                  type="text"
                  value={editedUserData.title}
                  onChange={(e) =>
                    setEditedUserData({
                      ...editedUserData,
                      title: e.target.value,
                    })
                  }
                />
              </label>
              <button
                className="btn btn-primary p-2 my-3"
                onClick={handleEditSubmit}
              >
                Save
              </button>
              <button
                className="btn btn-secondary p-2 my-3"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div>
                <h2>
                    {profileInfo?.title}
                  </h2>
                  <p>{profileInfo?.organization}</p>
                  <p>{profileInfo?.location}</p>
                  <p>{profileInfo?.start_date}</p>
                  <p>{profileInfo?.end_date}</p>
            </div>
          ) }          
      {imageSrc && (
        <img src={imageSrc} alt="Uploaded Image" height={150} width={150} />
      )}
      <br></br>
                  <button
                    className="btn btn-primary p-2"
                    onClick={() => {
                      // Initialize the editedUserData state when entering edit mode
                      setEditedUserData({
                        title: profileInfo?.title,
                        organization: profileInfo?.organization,
                        location: profileInfo?.location,
                        start_date: profileInfo?.start_date,
                        end_date: profileInfo?.end_date
                      });
                      setEditMode(true);
                    }}
                  >
                       Edit Work Profile
                  </button>
                </TabPanel>
              </Tabs>
  
        </div>
      )}
    </div>
  );
};

export default Profile;
