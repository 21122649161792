import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../environment";
import Header from "../header/Header";
import ImageEdit from "../images/image-edit/ImageEdit";

const SubProcedureEntityEdit = ({onClose, onUpdate, sub_procedure_entities_id}) => {
  const [newSubProcedureEntData, setNewSubProcedureEntData] = useState({
    sub_procedure_entities_comments: "",
  });
  const [sub_procedure_id, setSubProcId] = useState("");
  const [procedure_id, setProcId] = useState("");
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  useEffect(() => {
    // Fetch procedure data based on procedure_id
    fetchSubProcedureEntData(sub_procedure_entities_id);
  }, [sub_procedure_entities_id]);

  const fetchSubProcedureEntData = async (subProcedureEntId) => {
    try {
      const subProcEntResponse = await fetch(
        `${apiUrl}/sub-procedure-entities/${subProcedureEntId}`
      );

      if (subProcEntResponse.ok) {
        const subProcedureEntData = await subProcEntResponse.json();
        setSubProcId(subProcedureEntData.subProcedureEntity.sub_procedure_id);
        setNewSubProcedureEntData({
          sub_procedure_entities_comments:
            subProcedureEntData.subProcedureEntity.comments,
        });
        try {
          const subProcResponse = await fetch(
            `${apiUrl}/sub-procedures/${subProcedureEntData.subProcedureEntity.sub_procedure_id}`
          );

          if (subProcResponse.ok) {
            const subProcedureData = await subProcResponse.json();
            setProcId(subProcedureData.subProcedureRecord.procedure_id);
            try {
              const userAccountId = subProcedureEntId;
              const image_type = 'sub_procedure_entities_pic';
              const image_status = 'active';
              const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}/multiple`, {
                method: "GET",
                headers: { "authorization": localStorage.authorization },
              });
            
              if (imageResponse.ok) {
              let imageData = [];
              imageData = await imageResponse.json();
              setImages(imageData);
              } else {
                console.error("Failed to fetch images:", imageResponse.status);
                setImages([]);
              }
                      
            } catch (error) {
              console.error("Error fetching images:", error.message);
              setImages([]);
            }
          } else {
            console.error("Failed to fetch procedure data");
          }
        } catch (error) {
          console.error("Error fetching procedure data:", error);
        }
      } else {
        console.error("Failed to fetch procedure data");
      }
    } catch (error) {
      console.error("Error fetching procedure data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the local state only if the value has changed
    if (newSubProcedureEntData[name] !== value) {
      setNewSubProcedureEntData({
        ...newSubProcedureEntData,
        [name]: value,
      });
    }
  };

  const handleImageUpload = (files) => {
    if (files) {
      const fileList = Object.values(files); // Convert object to array
      setSelectedFiles(fileList);
    }
  };

  const handleCancelEdit = () => {
    navigate(`/procedure/${procedure_id}`);
  };

  const updateSubProcedureEntRecord = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apiUrl}/sub-procedure-entities/${sub_procedure_entities_id}/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sub_procedure_id: sub_procedure_id,
            comments: newSubProcedureEntData.sub_procedure_entities_comments,
          }),
        }
      );

      if (response.ok) {
        if (selectedFiles.length > 0) {
          const formData = new FormData();
          formData.append("user_account_id", sub_procedure_entities_id);
          if (Array.isArray(selectedFiles)) {
            selectedFiles.forEach((file, index) => {
              formData.append(`sub_proc_ent_pic`, file);
              formData.append("image_format", file.type);
            });
          } else {
            console.error("selectedFiles is not an array");
          }
          formData.append("image_type", "sub_procedure_entities_pic");
          formData.append("image_status", "active");

          const fieldName = "sub_proc_ent_pic";
        const imageUploadResponse = await fetch(
          `${apiUrl}/images/create-multiple/${fieldName}`,
          {
            method: "POST",
            body: formData,
          }
        );

          if (!imageUploadResponse.ok) {
            console.error("Failed to upload images!");
          }
        }
        navigate(`/procedure/${procedure_id}`);
      } else {
        console.error("Failed to update procedure record");
      }
    } catch (error) {
      console.error("Error updating procedure record:", error);
    }
  };

  return (
    <div>
      <form onSubmit={updateSubProcedureEntRecord}>
        <div>
          <label htmlFor="sub_procedure_entities_comments">Sub Procedure Entities Comment</label>
          <input
            type="text"
            name="sub_procedure_entities_comments"
            className="form-control my-3"
            value={newSubProcedureEntData.sub_procedure_entities_comments}
            onChange={handleInputChange}
            required
          />
        </div>
        {Array.isArray(images) && images.length > 0 ? (
            images.map((image, index) => (
              <ImageEdit
                key={index}
                imageUrl={image.presignedURL}
                imageFormat={image.image_format}
                file_id={image.file_id}
                userAccountId={sub_procedure_id}
                image_type={'sub_procedure_pic'}
              />
            ))
          ) : (
            <p>No images available.</p>
          )}
    <br></br>
            <label>Add a new file</label>
            <input
              type="file"
              name="sub_proc_ent_pic"
              placeholder="Upload file for sub procedure"
              className="form-control my-3"
              accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
              onChange={(e) => handleImageUpload(e.target.files)}
              multiple
            />
        <button className="btn btn-success btn-block my-3" type="submit">
          Update Sub Procedure Entities{" "}
        </button>{" "}
        &nbsp;
        <button
          className="btn btn-success btn-block my-3"
          onClick={handleCancelEdit}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default SubProcedureEntityEdit;
