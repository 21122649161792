import React from 'react'
import { Link } from 'react-router-dom'
import { userLogout } from '../../auth';
import { IoMdNotifications } from 'react-icons/io';
import { toast } from 'react-toastify';

const Header = ({ setAuth }) => {
    const role = localStorage.getItem("role");
    const logout = (e) => {
        e.preventDefault();
        try {
          userLogout();
          localStorage.removeItem("authorization");
          localStorage.removeItem("role");
          localStorage.removeItem("user_account_id");
          localStorage.removeItem("allUsers");
          setAuth(false);
          console.clear();
          toast.success("Logout successfully");
        } catch (err) {
          console.error(err.message);
        }
      };
  return (
    <div>
       <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Registry
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
              <Link className="nav-link active" to="/dashboard">Home</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link active" to="/patients">Patients</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link active" to="/profile">Profile</Link>
              </li>
              <li className="nav-item">
                      <Link className="nav-link active" to="/organizations">
                      Organizations
                      </Link>
                    </li>
              {role === "admin" && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/organization-approvals">
                      Organization Approvals
                      </Link>
                    </li>
                    &nbsp;
                    <li className="nav-item">
                      <Link className="nav-link active" to="/member-request">
                      User Requests
                      </Link>
                    </li>
                    &nbsp;
                    <li className="nav-item">
                      <Link className="nav-link active" to="/notifications">
                        <IoMdNotifications />
                      </Link>
                    </li>
                  </>
                )}
            </ul>
            
              <button className="btn btn-primary ml-3" onClick={(e) => logout(e)}>
                Logout
              </button>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
