import React, { useState } from "react";
import { createEntitiesParameter } from "./services/EntitiesParameterService";

const EntityParamsCreate = ({ onClose, onUpdate, sub_procedure_entities_id }) => {
  const [newEntData, setNewEntityData] = useState({
    entities_parameters_name: "",
    entities_parameters_value: "",
    entities_parameters_units: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEntityData((prevData) => ({ ...prevData, [name]: value }));
  };

  const addNewEnt = async (event) => {
    event.preventDefault();

    try {
      const entitiesParameterData = {
        name: newEntData.entities_parameters_name,
        value: newEntData.entities_parameters_value,
        units: newEntData.entities_parameters_units,
        sub_procedure_entities_id: sub_procedure_entities_id
      }
      const response = await createEntitiesParameter(entitiesParameterData)
      if (response) {
        const parseRes = response;
        onUpdate();
        onClose();
      }
    } catch (error) {
      console.error("Error while creating new sub procedure record:", error);
    }
  };

  return (
    <div>
      <form onSubmit={addNewEnt}>
        <div>
          <label htmlFor="entities_parameters_name">entities parameters name</label>
          <input
            type="text"
            name="entities_parameters_name"
            className="form-control my-3"
            value={newEntData.entities_parameters_name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="entities_parameters_value">entities parameters value</label>
          <input
            type="text"
            name="entities_parameters_value"
            className="form-control my-3"
            value={newEntData.entities_parameters_value}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="entities_parameters_units">entities parameters units</label>
          <input
            type="text"
            name="entities_parameters_units"
            className="form-control my-3"
            value={newEntData.entities_parameters_units}
            onChange={handleInputChange}
            required
          />
        </div>
        <button className="btn btn-success btn-block my-3" type="submit">
          Add Entities Parameters
        </button>
      </form>
      <button type="button" className="btn btn-success btn-block my-3" onClick={onClose}>
        Cancel
      </button>
      <br></br>
    </div>
  );
};

export default EntityParamsCreate;
