import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../src/userpool';
export const authenticate=(Email,Password)=>{
    console.log(Password)
    return new Promise((resolve,reject)=>{
        const user=new CognitoUser({
            Username:Email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:Email,
            Password
        });

        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                console.log("login successful");
                resolve(result);
            },
            onFailure:(err)=>{
                console.log("login failed",err);
                reject(err);
            }
        });
    });
};

export const userLogout = () => {
    const user = userpool.getCurrentUser();
    user.signOut();
    console.clear();
    window.location.href = '/';
};

export const forgotPassword = (Email) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: Email,
        Pool: userpool,
      });
  
      user.forgotPassword({
        onSuccess: (data) => {
          console.log("Password reset initiated", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.log("Password reset failed", err);
          reject(err);
        },
        inputVerificationCode: (data) => {
          console.log("Input verification code", data);
          resolve(data);
        },
      });
    });
  };
  
  export const confirmResetPassword = (Email, VerificationCode, NewPassword) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: Email,
        Pool: userpool,
      });
  
      user.confirmPassword(VerificationCode, NewPassword, {
        onSuccess: () => {
          console.log("Password reset confirmed");
          resolve();
        },
        onFailure: (err) => {
          console.log("Password reset confirmation failed", err);
          reject(err);
        },
      });
    });
  };