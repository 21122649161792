import React, {Fragment, useState} from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { authenticate } from '../../auth';
import { userLogin } from '../users/services/UserService';

const Login = ({setAuth}) => {
  const [errorText, setErrorText] = useState('')

  const [inputs, setInputs] = useState({
    email_id: '',
    password: ''
  });
  const [touchedFields, setTouchedFields] = useState({
    email_id: false,
    password: false,
  });
  const {email_id, password} = inputs;
  const onChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value});
};
const onBlur = (e) => {
  const fieldName = e.target.name;
  setTouchedFields({ ...touchedFields, [fieldName]: true });
};

const clearErrorTextAfterDelay = () => {
  setTimeout(() => {
    setErrorText('');
  }, 5000);
};

const onSubmitForm = async(e) => {
  e.preventDefault();

  setTouchedFields({
    email_id: true,
    password: true,
  });

  const requiredFields = [
    "email_id",
    "password",
  ];
  let isFormValid = true;
  for (const field of requiredFields) {
    if (inputs[field].trim() === "") {
      toast.error(`${field.replace("_", " ")} is required`);
      isFormValid = false;
    }
  }

  if (!isFormValid) {
    console.log("Some properties are missing values.");
    return; // Exit the function if the form is not valid
  }
  try {
      const loginData = {email_id, password}
      const response = await userLogin(loginData);
      if (response) {
        localStorage.setItem("user_id", response.user.user_account_id);
        authenticate(email_id, password)
          .then((data)=>{
            const jwtToken = data.accessToken.jwtToken;
            localStorage.setItem("authorization", jwtToken);
            setAuth(true);
            toast.success("Logged in Successfully");    
          },(err)=>{
            console.log(err);
            console.error(err.message)
          })
      } else {
        setAuth(false);
        toast.error(response);
        setErrorText(response);
        clearErrorTextAfterDelay();
      }
  } catch (err) {
      console.error(err.message);
      toast.error(err.message);
  }
}
  return (
    <Fragment>
        <h1 className='text-center my-5'>Login</h1>
        <form onSubmit={onSubmitForm}>
        <div className="row">
        <div className="col-12 col-md-4">
          </div>
        <div className="form-group col-12 col-md-4">
        <input
          type="email"
          name="email_id"
          placeholder="Email"
          className="form-control my-3"
          value={email_id}
          onChange={onChange}
          onBlur={onBlur}
        />
        {touchedFields.email_id && email_id.trim() === "" && (
          <span className="text-danger">Email is required</span>
        )}
          </div>
          </div>
          <div className="row">
        <div className="col-12 col-md-4">
          </div>
        <div className="form-group col-12 col-md-4">
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="form-control my-3"
          value={password}
          onChange={onChange}
          onBlur={onBlur}
        />
        {touchedFields.password && password.trim() === "" && (
          <span className="text-danger">Password is required</span>
        )}
          </div>
          </div>
       <div className='row'>
        <div className='col-12 col-md-4'>

        </div>
        <div className='form-group col-12 col-md-4'>
        {errorText && <p style={{ color: 'red', padding: '5px' }}>{errorText}</p>}
        <button className='btn btn-success btn-block' disabled= {
          !((touchedFields.email_id && email_id.trim() !== '') &&
          (touchedFields.password && password.trim() !== ''))
        }>Login</button>
       <div className='pt-3'>
       <Link to={'/register'}>Register</Link>
       <br></br>
       <Link to={'/forgot-password'}>Forgot Password?</Link>
       </div>
        </div>
       </div>
        </form>
        <ToastContainer />
    </Fragment>
    )
}

export default Login
