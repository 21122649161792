import React from 'react';

const OrganizationForm = ({ 
  newOrganizationData, 
  isEditing, 
  handleInputChange, 
  handleImageChange, 
  handlePubPrivateChange, 
  handleCommentsChange, 
  handleAddOrganization, 
  handleUpdate, 
  closeModal 
}) => {
  return (
    <form onSubmit={isEditing ? handleUpdate : handleAddOrganization}>
      <div>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          className="form-control my-3"
          value={newOrganizationData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="contact_email">Contact Email</label>
        <input
          type="email"
          name="contact_email"
          className="form-control my-3"
          value={newOrganizationData.contact_email}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="website">Website</label>
        <input
          type="url"
          name="website"
          className="form-control my-3"
          value={newOrganizationData.website}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor="image">Image</label>
        <input
          type="file"
          name="image"
          className="form-control my-3"
          onChange={handleImageChange}
          accept="image/*"
          required
        />
      </div>
      <div>
        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          className="form-control my-3"
          value={newOrganizationData.description}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor="personal">Personal</label>
        <select
          name="personal"
          className="form-control my-3"
          value={newOrganizationData.personal}
          onChange={handlePubPrivateChange}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div>
      <div>
        <label htmlFor="comments">Comments</label>
        <input
          type="text"
          name="comments"
          className="form-control my-3"
          value={newOrganizationData.comments}
          onChange={handleCommentsChange}
        />
      </div>
      <button className="btn btn-success btn-block my-3" type="submit">
        {isEditing ? "Update Organization" : "Add Organization"}
      </button>
      <button
        className="btn btn-secondary btn-block my-3"
        onClick={closeModal}
      >
        Cancel
      </button>
    </form>
  );
};

export default OrganizationForm;
