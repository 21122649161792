import { resetPassword } from 'aws-amplify/auth';
import { useState } from 'react';
import { confirmResetPassword } from '../../auth';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail, isValidPassword } from '../../Validation';
import { getUserPassword, updatePassword } from '../users/services/UserService';

const ResetPassword = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [nextStep, setNextStep] = useState('');
  const [errorText, setErrorText] = useState('')

  const handleResetPassword = async () => {
    if (!isValidEmail(username)) {
        console.error('Invalid email');
        setErrorText('Invalid Email');
        clearErrorTextAfterDelay();
        return;
      }
    try {
      const output = await resetPassword({ username });
      await getOldPass(username);
      handleResetPasswordNextSteps(output);
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    setNextStep(nextStep.resetPasswordStep);

    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        // Collect the confirmation code from the user and pass it to confirmResetPassword.
        // You can update the UI to prompt the user for the confirmation code.
        break;
      case 'DONE':
        // You can redirect the user to the login page or show a success message.
        break;
      default:
        break;
    }
  };

  const handleConfirmResetPassword = async () => {
    try {
          if (!isValidPassword(newPassword)) {
            console.error('Invalid password');
            setErrorText('Invalid password');
            clearErrorTextAfterDelay();
            return;
          }
      const email = username;
      // Update password
      await confirmResetPassword(email, confirmationCode, newPassword);
      const emailId = username;
      const response = await updatePassword(emailId, newPassword)
      if (response) {
        navigate('/login');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOldPass = async (emailId) => {
    try {
      const response = getUserPassword(emailId)
      const parseRes = await response;
      setOldPassword(parseRes.user.password);
    } catch (err) {
      console.error(err.message);
    }
  };

  const clearErrorTextAfterDelay = () => {
    setTimeout(() => {
      setErrorText('');
    }, 5000);
  };

  return (
    <div>
      <h6>
        <Link to={'/login'}>Go back</Link>
      </h6>
    <h2>Password Reset</h2>
    <label>Email:</label>
    <input
      placeholder='Enter the Email id'
      required
      className={`form-control pb-2 ${username && !isValidEmail(username) ? 'is-invalid' : ''}`}
      type='email'
      value={username}
      onChange={(e) => setUsername(e.target.value)}
    />
    {username && !isValidEmail(username) && <div className="invalid-feedback">Invalid Email</div>}
    <br />
    {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
    <button
      className='btn btn-primary ml-3 pt-2'
      onClick={handleResetPassword}
      disabled={!username || nextStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE' || !isValidEmail(username)}
    >
      Reset Password
    </button>
    &nbsp;

    {nextStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE' && (
      <div className='pt-3'>
        <label>Confirmation Code:</label>
        <input
          className={`form-control pt-2 ${confirmationCode && !confirmationCode ? 'is-invalid' : ''}`}
          required
          type='text'
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
        {confirmationCode && !confirmationCode && <div className="invalid-feedback">Confirmation code is required</div>}

        <label>New Password:</label>
        <input
          className={`form-control ${newPassword && !isValidPassword(newPassword) ? 'is-invalid' : ''}`}
          required
          type='password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {newPassword && !isValidPassword(newPassword) && <div className="invalid-feedback">Invalid password</div>}
        <br />

        {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
        <button
          className='btn btn-primary ml-3'
          onClick={handleConfirmResetPassword}
          disabled={!confirmationCode || !isValidPassword(newPassword)}
        >
          Confirm Reset Password
        </button>
      </div>
    )}
  </div>
  );
};

export default ResetPassword;
