import React, { useState, useEffect } from 'react';
import FormInput from '../../forms/FormInput'; // Adjust the import path as necessary

const ProcedureForm = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState(initialValues);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formComplete, setFormComplete] = useState(false); // State to track form completion

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  // Effect to check if all required fields are filled whenever form data changes
  useEffect(() => {
    const isFormComplete = Object.entries(formData).every(([key, value]) => {
      // Check if the field is required and not empty, except for patient_name
      if (key === 'patient_name' || key === 'new_patient_name' || key === 'new_patient_age' || key === 'new_patient_contact' || key === 'new_patient_gender' ) return true; // Skip validation for patient_name
      return value !== '';
    });
    setFormComplete(isFormComplete);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (files) => {
    if (files) {
      setSelectedFiles(Object.values(files));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData, selectedFiles);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Select a patient:
          <select
            name="patient_name"
            value={formData.patient_name}
            onChange={handleChange}
            className="form-control my-3"
          >
            <option value="">Select a patient</option>
            {formData.patientData.map((patient) => (
              <option key={patient.patient_id} value={patient.name}>
                {patient.name}
              </option>
            ))}
          </select>
        </label>

        {formData.patient_name === "" && (
          <div>
            <FormInput
              label="New Patient Name"
              name="new_patient_name"
              value={formData.new_patient_name}
              onChange={handleChange}
              required
            />
            <FormInput
              label="Age"
              name="new_patient_age"
              type="number"
              value={formData.new_patient_age}
              onChange={handleChange}
              required
            />
            <FormInput
              label="Gender"
              name="new_patient_gender"
              value={formData.new_patient_gender}
              onChange={handleChange}
              required
            />
            <FormInput
              label="Contact"
              name="new_patient_contact"
              type="number"
              value={formData.new_patient_contact}
              onChange={handleChange}
              required
            />
          </div>
        )}
      </div>
      <FormInput
        label="Procedure Status"
        name="procedure_status"
        value={formData.procedure_status}
        onChange={handleChange}
        required
      />
      <FormInput
        label="Procedure Notes"
        name="procedure_notes"
        value={formData.procedure_notes}
        onChange={handleChange}
        required
      />
      <div className='row'>
        <div className="col-md-12 col-12">
          <input
            type="file"
            name="proc_pic"
            className="form-control my-3"
            accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
            onChange={(e) => handleImageUpload(e.target.files)}
            multiple
          />
        </div>
      </div>
      <button className="btn btn-success btn-block my-3" type="submit" disabled={!formComplete}>
        Add Procedure
      </button>
    </form>
  );
};

export default ProcedureForm;
