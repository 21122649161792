import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchImage } from '../images/services/ImageService';
import { fetchOrganizationDetailsById } from '../organizations/services/OrganizationService'
import Header from '../header/Header';

const OrganizationDetails = (props) => {
  const { organization_id: paramId } = useParams();
  const organization_id = props.organization_id || paramId; // Use prop if available, otherwise use param

  const [imageSrc, setImageSrc] = useState("");
  const [orgData, setOrgData] = useState();

  useEffect(() => {
    const getImage = async () => {
      try {
        const orgResData = await fetchOrganizationDetailsById(organization_id);
        console.log(orgResData);
        setOrgData(orgResData);
        if (orgResData) {
          const entityId = organization_id;
          const imageType = 'organization_logo'
          const imageUrl = await fetchImage(entityId, imageType);
          if (imageUrl) {
            setImageSrc(imageUrl);
          } else {
            console.error("Failed to fetch image.");
          }
        } else {
          console.error("Failed to fetch organization details:");
        }
      } catch (error) {
        console.error("Error fetching organization:", error.message);
      }
    };

    getImage();
  }, [organization_id]);

  return (
    <div>
      <p>Contact - {orgData?.contact_email}</p>
      <p>About - {orgData?.description}</p>
      {imageSrc && (
        <img src={imageSrc} alt="Organization Logo" height={150} width={150} />
      )}
    </div>
  );
};

export default OrganizationDetails;
