import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import UsersCrud from "../users/component/Users-crud";
import Skeleton from "react-loading-skeleton";
import { userLogout } from "../../auth";
import Header from "../header/Header";
import OrganizationProcedures from "../organizations/OrganizationProcedures";
import { postUserDashboard } from "../users/services/UserService";
import { getAllApprovals, getApprovals } from "../notifications/services/NotificationsService";

const Dashboard = ({ setAuth }) => {
  const [user, setUser] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);

  async function getUserData() {
    try {
      const data = await postUserDashboard();
      console.log(data.user.role);
      setUser(data.user);
      localStorage.setItem("role", data.user.role);
      localStorage.setItem("organization_id", data.user.organization_id);
    } catch (err) {
      console.error(err.message);
    }
  }
  

  const logout = (e) => {
    e.preventDefault();
    try {
      userLogout();
      localStorage.removeItem("authorization");
      localStorage.removeItem("role");
      localStorage.removeItem("user_account_id");
      localStorage.removeItem("allUsers");
      setAuth(false);
      console.clear();
      toast.success("Logout successfully");
    } catch (err) {
      console.error(err.message);
    }
  };

  async function getApprovalStatus() {
    try {
      const response = await getAllApprovals()
      console.log(response)
      const approvals = response.approvals;

      // Check if the data is an array before using the find method
      const userApproval = approvals.find(
        (approval) => approval.user_id === user.user_account_id
      );
      console.log(userApproval);

      // Set the approval status based on the found approval object, or set it to "pending" if not found
      setApprovalStatus(userApproval ? userApproval.status : "pending");
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (user) {
      getApprovalStatus();
    }
  }, [user]);

  if (!user || !approvalStatus) {
    return <Skeleton count={10} />;
  }

  // Conditional rendering based on the user's role
  return (
    <Fragment>
      {approvalStatus === "approved" && (
        <Header />
      )}
      <h1 className="text-center my-3">Dashboard</h1>
      {user.role !== "admin" && approvalStatus === "pending" && (
        <div>
          <p>Approval is pending.</p>
          <button className="btn btn-primary ml-3" onClick={(e) => logout(e)}>
            Logout
          </button>
        </div>
      )}
      {approvalStatus === "approved" && user.role === "Doctor" && (
        <div>
          <h2>Welcome, Dr. {user.first_name || <Skeleton count={3} />}!</h2>
          {/* <PatientCrud /> */}
          <OrganizationProcedures />
          {/* Additional components and sections specific to Doctors */}
        </div>
      )}
      {user.role == "admin" && (
        <div>
          <h2>Welcome, {user.first_name || <Skeleton count={3} />}!</h2>
          {/* <Notifications /> */}
          {/* <Organization /> */}
          <UsersCrud />
          {/* Additional components and sections for other roles */}
        </div>
      )}
      {approvalStatus === "rejected" && (
        <div>
          <p>Approval was rejected. Request you to register again.</p>
          <button className="btn btn-primary ml-3" onClick={(e) => logout(e)}>
            Logout
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default Dashboard;
