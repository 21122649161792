import axios from 'axios';
import { apiUrl } from '../../../environment';

// Fetch all procedure records
export const fetchAllProcedureRecords = async () => {
  try {
    const response = await axios.get(`${apiUrl}/procedures`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all procedure records:", error);
    return [];
  }
};

export const fetchProcedureDetails = async (procedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/procedures/${procedureId}/get-details`);
    return response.data;
  } catch (error) {
    console.error("Error fetching procedure details:", error);
    return null;
  }
};

// Fetch procedure record by ID
export const fetchProcedureRecordById = async (procedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/procedures/${procedureId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching procedure record by ID:", error);
    return null;
  }
};

// Fetch procedure record details by procedure ID
export const fetchProcedureRecordsByProcedureId = async (procedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/procedures/${procedureId}/get-details`);
    return response.data;
  } catch (error) {
    console.error("Error fetching procedure records by procedure ID:", error);
    return null;
  }
};

// Create a new procedure record
export const createProcedureRecord = async (procedureData) => {
  try {
    const response = await axios.post(`${apiUrl}/procedures/create`, procedureData);
    return response.data;
  } catch (error) {
    console.error("Error creating procedure record:", error);
    return null;
  }
};

// Update a procedure record
export const updateProcedureRecord = async (procedureId, procedureData) => {
  try {
    const response = await axios.put(`${apiUrl}/procedures/${procedureId}/update`, procedureData);
    return response.data;
  } catch (error) {
    console.error("Error updating procedure record:", error);
    return null;
  }
};

// Delete a procedure record
export const deleteProcedureRecord = async (procedureId) => {
  try {
    const response = await axios.put(`${apiUrl}/procedures/${procedureId}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error deleting procedure record:", error);
    return null;
  }
};

// Fetch procedure records by organization ID
export const fetchProcedureRecordsByOrgId = async (orgId) => {
  try {
    const response = await axios.get(`${apiUrl}/procedures/${orgId}/org`);
    return response.data;
  } catch (error) {
    console.error("Error fetching procedure records by organization ID:", error);
    return [];
  }
};
