import axios from 'axios';
import { apiUrl } from '../../../environment';
import { uploadImage } from '../../images/services/ImageService';  // Import the generic uploadImage function

// Fetch all organizations
export const fetchOrganizationData = async () => {
  try {
    const response = await axios.get(`${apiUrl}/organizations`, {
        headers: { "authorization": localStorage.authorization }
    });
    return response.data.organizations; 
  } catch (error) {
    console.error("Error fetching organization data:", error);
    return []; 
  }
};

export const fetchOrganizationDetailsById = async (organization_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organizations/${organization_id}/details`);
      console.log(response);
      return response.data.organization;
    } catch (error) {
      console.error("Error fetching organization data:", error);
      return [];
    }
  };

// Add a new organization
export const addOrganization = async (newOrganizationData, role, personal) => {
  console.log(personal);
  try {
    // Set status based on role and personal flag
    let status;
    if (role === "admin") {
      status = "active";
    } else if (role === "Doctor" && personal === 'true') {
      status = "active";
      console.log(status);
    } else {
      status = "inactive";
    }

    let newOrganizationDataWithStatus = {
      ...newOrganizationData,
      status: status
    };

    console.log(newOrganizationDataWithStatus);

    const orgResponse = await axios.post(`${apiUrl}/organizations/create`, newOrganizationDataWithStatus, {
      headers: { "Content-Type": "application/json" }
    });

    if (!orgResponse.data) {
      console.error("Failed to add a new organization.");
      return null;
    }

    const createdOrg = orgResponse.data;
    console.log(createdOrg);
    await uploadImage(newOrganizationData.image, createdOrg.organization.organization_id, 'organization_logo');

    if (role === "Doctor") {
      const userId = localStorage.getItem("user_id");
      const newOrganizationApprovalData = {
        user_id: userId,
        created_time: new Date().toISOString(),
        updated_time: new Date().toISOString(),
        status: status,
        comments: "",
        organization_id: createdOrg.organization.organization_id
      };

      const response = await axios.post(`${apiUrl}/organization-approval/create`, newOrganizationApprovalData, {
        headers: { "Content-Type": "application/json" }
      });

      if (!response.data) {
        console.error("Failed to create organization approval.");
        return null;
      }
    }

    return createdOrg;
  } catch (error) {
    console.error("Error adding a new organization: ", error);
    return null;
  }
};


// Update an organization
export const updateOrganization = async (organization_id, updatedData) => {
  try {
    console.log(organization_id)
    const response = await axios.put(`${apiUrl}/organizations/${organization_id}/update`, updatedData, {
      headers: { "Content-Type": "application/json" }
    });

    if (response.status === 200) {
      if (updatedData.image) {
        await uploadImage(updatedData.image, organization_id, 'organization_logo');
      }
      return true;
    } else {
      console.error("Failed to update the organization.");
      return false;
    }
  } catch (error) {
    console.error("Error updating the organization: ", error);
    return false;
  }
};


// Delete an organization
export const deleteOrganization = async (organizationId) => {
    try {
      const response = await axios.delete(`${apiUrl}/organizations/${organizationId}/delete`);
  
      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to delete the organization.");
        return false;
      }
    } catch (error) {
      console.error("Error deleting the organization: ", error);
      return false;
    }
  };

export const fetchOrgApprovalDetails = async (organizationApprovalId) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-approval/${organizationApprovalId}/details`);
      return response.data.organizationApproval;
    } catch (error) {
      console.error("Error fetching approval details:", error);
      return null;
    }
  };

  export const fetchApprovalsByUserId = async (user_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-approval/${user_id}/user-details`);
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("Error fetching approvals by user ID:", error);
      return [];
    }
  };

  export const createApproval = async (approvalData) => {
    try {
      const createResponse = await axios.post(`${apiUrl}/organization-approval/create`, approvalData, {
        headers: { "Content-Type": "application/json" }
      });
  
      return createResponse.data.organization_approval_id;
    } catch (error) {
      console.error('Error creating approval:', error);
      throw error;
    }
  };
  
  
  // Update approval status by ID
  export const updateOrgApprovalStatus = async (organizationApprovalId, status) => {
    try {
      const response = await axios.put(`${apiUrl}/organization-approval/${organizationApprovalId}/update`, {
        status: status
      }, {
        headers: { "Content-Type": "application/json" }
      });
  
      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to update approval status.");
        return false;
      }
    } catch (error) {
      console.error("Error updating approval status:", error);
      return false;
    }
  };

  export const getOrganizationMembers = async (organizationId) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-user-link/organization/${organizationId}`);
      
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Error fetching organization-user links for organization_id: ${organizationId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching organization-user links: ${error.message}`);
      return null;
    }
  };

  export const getOrganizationUserLinkByUserId = async (user_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-user-link/user/${user_id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Failed to fetch organization user link data:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization user link data:', error.message);
      return null;
    }
  };

  export const getPatientFromOrganizationPatientLink = async (org_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-patient-link/${org_id}/org`);
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      } else {
        console.error('Failed to fetch organization user link data:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization user link data:', error.message);
      return null;
    }
  };

  export const getAllOrganizationPatientLinks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/organization-patient-link`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Failed to fetch all organization-patient links:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching all organization-patient links:', error.message);
      return null;
    }
  };
  
  // Fetch organization-patient links by organization ID
  export const getOrganizationPatientLinkByOrgId = async (organization_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-patient-link/${organization_id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Failed to fetch organization-patient link by organization ID ${organization_id}:`, response.status);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching organization-patient link by organization ID ${organization_id}:`, error.message);
      return null;
    }
  };
  
  // Create a new organization-patient link
  export const createOrganizationPatientLink = async (organizationPatientLinkData) => {
    try {
      const response = await axios.post(`${apiUrl}/organization-patient-link/create`, organizationPatientLinkData, {
        headers: { "Content-Type": "application/json" }
      });
      if (response.data) {
        return response.data;
      } else {
        console.error('Failed to create organization-patient link:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error creating organization-patient link:', error.message);
      return null;
    }
  };
  
  
  // Update an existing organization-patient link
  export const updateOrganizationPatientLink = async (organization_patient_link_id, organizationPatientLinkData) => {
    try {
      const response = await axios.put(`${apiUrl}/organization-patient-link/${organization_patient_link_id}`, organizationPatientLinkData, {
        headers: { "Content-Type": "application/json" }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Failed to update organization-patient link with ID ${organization_patient_link_id}:`, response.status);
        return null;
      }
    } catch (error) {
      console.error(`Error updating organization-patient link with ID ${organization_patient_link_id}:`, error.message);
      return null;
    }
  };
  
  // Delete an organization-patient link by ID
  export const deleteOrganizationPatientLink = async (organization_patient_link_id) => {
    try {
      const response = await axios.delete(`${apiUrl}/organization-patient-link/${organization_patient_link_id}`);
      if (response.status === 200) {
        return true;
      } else {
        console.error(`Failed to delete organization-patient link with ID ${organization_patient_link_id}:`, response.status);
        return false;
      }
    } catch (error) {
      console.error(`Error deleting organization-patient link with ID ${organization_patient_link_id}:`, error.message);
      return false;
    }
  };
  
  // Delete organization-patient links by patient ID
  export const deleteOrganizationPatientLinkByPatientId = async (patient_id) => {
    try {
      const response = await axios.delete(`${apiUrl}/organization-patient-link/patient/${patient_id}`);
      if (response.status === 200) {
        return true;
      } else {
        console.error(`Failed to delete organization-patient link by patient ID ${patient_id}:`, response.status);
        return false;
      }
    } catch (error) {
      console.error(`Error deleting organization-patient link by patient ID ${patient_id}:`, error.message);
      return false;
    }
  };


  export const createOrganizationUserLink = async (organizationUserLinkData) => {
    try {
      // Create organization user link
      const response = await axios.post(`${apiUrl}/organization-user-link/create`, {
        organization_id: organizationUserLinkData.organization_id,
        user_id: organizationUserLinkData.user_id,
        status: organizationUserLinkData.status,
        account_type: organizationUserLinkData.account_type
      });
      
      if (response.status === 201) {
        return response.data;
      } else {
        throw new Error('Failed to create organization user link');
      }
    } catch (error) {
      console.error(`Error creating organization user link: ${error.message}`);
      return null;
    }
  };
  
  export const updateOrganizationUserLink = async (organization_user_link_id, organizationUserLinkData) => {
    try {
      const response = await axios.put(`${apiUrl}/organization-user-link/${organization_user_link_id}/update`, {
        organization_id: organizationUserLinkData.organization_id,
        user_id: organizationUserLinkData.user_id,
        status: organizationUserLinkData.status
      });
      
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update organization user link');
      }
    } catch (error) {
      console.error(`Error updating organization user link: ${error.message}`);
      return null;
    }
  };

  export const getAllOrganizationUserLinks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/organization-user-link`);
      
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Failed to fetch all organization user links');
        return null;
      }
    } catch (error) {
      console.error(`Error fetching all organization user links: ${error.message}`);
      return null;
    }
  };
  
  export const getOrganizationUserLinkById = async (organization_user_link_id) => {
    try {
      const response = await axios.get(`${apiUrl}/organization-user-link/${organization_user_link_id}`);
      
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Failed to fetch organization user link with ID ${organization_user_link_id}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching organization user link: ${error.message}`);
      return null;
    }
  };
  
  export const deleteOrganizationUserLink = async (organization_user_link_id) => {
    try {
      const response = await axios.delete(`${apiUrl}/organization-user-link/${organization_user_link_id}/delete`);
      
      if (response.status === 200) {
        return true; // Successfully deleted
      } else {
        console.error(`Failed to delete organization user link with ID ${organization_user_link_id}`);
        return false;
      }
    } catch (error) {
      console.error(`Error deleting organization user link: ${error.message}`);
      return false;
    }
  };
  