import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../environment";
import Header from "../header/Header";
import ImageEdit from "../images/image-edit/ImageEdit";

const ProcedureEdit = ({onClose, onUpdate}) => {
  const { procedure_id } = useParams();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const org_id = localStorage.getItem("organization_id");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [newProcedureData, setNewProcedureData] = useState({
    procedure_status: "",
    procedure_notes: "",
  });

  useEffect(() => {
    // Fetch procedure data based on procedure_id
    fetchProcedureData(procedure_id);
  }, [procedure_id]);

  const fetchProcedureData = async (procedureId) => {
    try {
      const response = await fetch(`${apiUrl}/procedures/${procedureId}`);

      if (response.ok) {
        const procedureData = await response.json();
        setNewProcedureData({
          procedure_status: procedureData.procedureRecord.status,
          procedure_notes: procedureData.procedureRecord.procedure_notes,
        });
        try {
          const userAccountId = procedure_id;
          const image_type = 'Procedure_pic';
          const image_status = 'active';
          const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}/multiple`, {
            method: "GET",
            headers: { "authorization": localStorage.authorization },
          });
        
          if (imageResponse.ok) {
            const imageData = await imageResponse.json();
            if (Array.isArray(imageData)) {
              setImages(imageData);
            } else {
              console.error("Image data is not an array:", imageData);
              setImages([]);
            }
          } else {
            console.error("Failed to fetch images:", imageResponse.status);
            setImages([]);
          }
        } catch (error) {
          console.error("Error fetching images:", error.message);
          setImages([]);
        }
      } else {
        console.error("Failed to fetch procedure data");
      }
    } catch (error) {
      console.error("Error fetching procedure data:", error);
    }
  };

  const handleCancelEdit = () => {
    navigate(`/procedure/${procedure_id}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the local state only if the value has changed
    if (newProcedureData[name] !== value) {
      setNewProcedureData({
        ...newProcedureData,
        [name]: value,
      });
    }
  };

  const handleImageUpload = (files) => {
    if (files) {
      const fileList = Object.values(files); // Convert object to array
      setSelectedFiles(fileList);
    }
  };

  const updateProcedureRecord = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apiUrl}/procedures/${procedure_id}/update`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            status: newProcedureData.procedure_status,
            procedure_notes: newProcedureData.procedure_notes,
            doctor_id: user_id,
            org_id: org_id,
          }),
        }
      );

      if (response.ok) {
        const procedure_data = await response.json();
        const procedure_id = procedure_data.updatedProcedureRecord.procedure_id;
        if (selectedFiles.length > 0) {
          const formData = new FormData();
          formData.append("user_account_id", procedure_id);
          if (Array.isArray(selectedFiles)) {
            selectedFiles.forEach((file, index) => {
              formData.append(`proc_pic`, file);
              formData.append("image_format", file.type);
            });
          } else {
            console.error("selectedFiles is not an array");
          }
          formData.append("image_type", "Procedure_pic");
          formData.append("image_status", "active");

          const fieldName = "proc_pic";
          const imageUploadResponse = await fetch(
            `${apiUrl}/images/create-multiple/${fieldName}`,
            {
              method: "POST",
              body: formData,
            }
          );

          if (!imageUploadResponse.ok) {
            console.error("Failed to upload images!");
          }
        }
        onUpdate();
        onClose();
        navigate(`/procedure/${procedure_id}`);
      } else {
        console.error("Failed to update procedure record");
      }
    } catch (error) {
      console.error("Error updating procedure record:", error);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div>
        <form onSubmit={updateProcedureRecord}>
          <div>
            <label htmlFor="procedure_status">Procedure Status</label>
            <input
              type="text"
              name="procedure_status"
              className="form-control my-3"
              value={newProcedureData.procedure_status}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="procedure_notes">Procedure Notes</label>
            <input
              type="text"
              name="procedure_notes"
              className="form-control my-3"
              value={newProcedureData.procedure_notes}
              onChange={handleInputChange}
              required
            />
          </div>
          {Array.isArray(images) && images.length > 0 ? (
  images.map((image, index) => (
    <ImageEdit
      key={index}
      imageUrl={image.presignedURL}
      imageFormat={image.image_format}
      file_id={image.file_id}
      userAccountId={procedure_id}
      image_type={'Procedure_pic'}
    />
  ))
) : (
  <p>No images available</p>
)}
    <br></br>
            <label>Add a new file</label>
            <input
              type="file"
              name="proc_pic"
              placeholder="Upload file for procedure"
              className="form-control my-3"
              accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
              onChange={(e) => handleImageUpload(e.target.files)}
              multiple
            />
          <button className="btn btn-success btn-block my-3" type="submit">
            Update Procedure{" "}
          </button>{" "}
          &nbsp;
          <button
            type="button"
            className="btn btn-success btn-block my-3"
            onClick={onClose}
          >
            Cancel
          </button>
        </form>
      </div>
    </>
  );
};

export default ProcedureEdit;
