import axios from 'axios';
import { apiUrl } from '../../../environment';

// Fetch all sub-procedure entities
export const fetchAllSubProcedureEntities = async () => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedure-entities`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all sub-procedure entities:", error);
    return [];
  }
};

// Fetch sub-procedure entity by ID
export const fetchSubProcedureEntityById = async (subProcedureEntityId) => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedure-entities/${subProcedureEntityId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching sub-procedure entity by ID:", error);
    return null;
  }
};

// Fetch sub-procedure entity by sub-procedure ID
export const fetchSubProcedureEntityBySubProcId = async (subProcedureId) => {
  try {
    const response = await axios.get(`${apiUrl}/sub-procedure-entities/sub-procedure/${subProcedureId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching sub-procedure entity by sub-procedure ID:", error);
    return null;
  }
};

// Create a new sub-procedure entity
export const createSubProcedureEntity = async (subProcedureEntityData) => {
  try {
    const response = await axios.post(`${apiUrl}/sub-procedure-entities/create`, subProcedureEntityData);
    return response.data;
  } catch (error) {
    console.error("Error creating sub-procedure entity:", error);
    return null;
  }
};

// Update a sub-procedure entity
export const updateSubProcedureEntity = async (subProcedureEntityId, updatedSubProcedureEntityData) => {
  try {
    const response = await axios.put(`${apiUrl}/sub-procedure-entities/${subProcedureEntityId}/update`, updatedSubProcedureEntityData);
    return response.data;
  } catch (error) {
    console.error("Error updating sub-procedure entity:", error);
    return null;
  }
};

// Delete a sub-procedure entity
export const deleteSubProcedureEntity = async (subProcedureEntityId) => {
  try {
    const response = await axios.put(`${apiUrl}/sub-procedure-entities/${subProcedureEntityId}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error deleting sub-procedure entity:", error);
    return null;
  }
};
