import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageView from '../images/image-view/ImageView';
import { apiUrl } from '../../environment';
import Header from '../header/Header';

const SubProcedureDetails = () => {
  const { sub_procedure_id } = useParams();
  const [subProcedure, setSubProcedure] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchData();
  }, [sub_procedure_id]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/sub-procedures/${sub_procedure_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubProcedure(data.subProcedureRecord);

        try {
          const userAccountId = sub_procedure_id;
          const image_type = 'sub_procedure_pic';
          const image_status = 'active';
          const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}/multiple`, {
            method: 'GET',
            headers: { 'authorization': localStorage.authorization },
          });

          if (imageResponse.ok) {
            const imageData = await imageResponse.json();
            if (Array.isArray(imageData)) {
              setImages(imageData);
            } else {
              console.error("Image data is not an array:", imageData);
              setImages([]);
            }
          } else {
            console.error("Failed to fetch images:", imageResponse.status);
            setImages([]);
          }
        } catch (error) {
          console.error("Error fetching images:", error.message);
          setImages([]);
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <Header />
      <h2>Sub Procedure Details</h2>
      {subProcedure ? (
        <div>
          <p><strong>Name:</strong> {subProcedure.name}</p>
          <p><strong>Type:</strong> {subProcedure.type}</p>
          <p><strong>Status:</strong> {subProcedure.status}</p>
          <p><strong>Created Time:</strong> {new Date(subProcedure.created_time).toLocaleString()}</p>
          <p><strong>Last Updated Time:</strong> {new Date(subProcedure.last_updated_time).toLocaleString()}</p>

          <h3>Sub Procedure Entities</h3>
          {subProcedure.sub_procedure_entities.map((entity, index) => (
            <div key={entity.sub_procedure_entities_id}>
              <p><strong>Comments:</strong> {entity.comments}</p>
              <p><strong>Created Time:</strong> {new Date(entity.created_time).toLocaleString()}</p>
              <p><strong>Last Updated Time:</strong> {new Date(entity.last_updated_time).toLocaleString()}</p>

              <h4>Entities Parameters</h4>
              {entity.entities_parameters.map((param, index) => (
                <div key={param.entities_parameters_id}>
                  <p><strong>Name:</strong> {param.name}</p>
                  <p><strong>Value:</strong> {param.value}</p>
                  <p><strong>Units:</strong> {param.units}</p>
                  <p><strong>Created Time:</strong> {new Date(param.created_time).toLocaleString()}</p>
                  <p><strong>Last Updated Time:</strong> {new Date(param.last_updated_time).toLocaleString()}</p>
                </div>
              ))}
            </div>
          ))}

          <h3>Images</h3>
          {images.map((image, index) => (
            <ImageView key={index} imageUrl={image.presignedURL} imageFormat={image.image_format} />
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SubProcedureDetails;
