import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import { createSubProcedureEntity, fetchSubProcedureEntityBySubProcId } from './services/SubProcedureEntityService';
import { uploadMultipleImages } from '../images/services/ImageService';

const SubProcedureEntityCreate = ({onClose, onUpdate, sub_procedure_id}) => {
 
  const [newSubProcedureEntData, setNewSubProcedureEntityData] = useState({
    sub_procedure_ent_cmnts: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

console.log(sub_procedure_id);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewSubProcedureEntityData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleImageUpload = (files) => {
    if (files) {
      const fileList = Object.values(files); // Convert object to array
      setSelectedFiles(fileList);
    }
  };

  const addNewSubProEnt = async (event) => {
    event.preventDefault();

    try {
      const subProcedureEntityData = {
        comments: newSubProcedureEntData.sub_procedure_ent_cmnts,
            sub_procedure_id: sub_procedure_id,
            status: "active"
      }
      const response = await createSubProcedureEntity(subProcedureEntityData)
      
      if(response) {
        const parseRes = response;
        if (selectedFiles.length > 0) {
          const sub_procedure_entities_id = parseRes.newSubProcedureEntity.sub_procedure_entities_id;
          const imageType = 'sub_procedure_entities_pic';
          const entityId = sub_procedure_entities_id;
          const fieldName = "sub_proc_ent_pic";
        
          const imageUploadResponse = await uploadMultipleImages(entityId, selectedFiles, fieldName, imageType);
        
          if (!imageUploadResponse) {
            console.error("Failed to upload images!");
          }
        }        
        onUpdate();
        onClose();
      }
    } catch (error) {
      console.error("Error while creating new sub procedure record:", error);
    }
  };
  
  return (
    <div>
      <form onSubmit={addNewSubProEnt}>
        <div>
          <label htmlFor="sub_procedure_ent_cmnts">Add Comment</label>
          <input
            type="text"
            name="sub_procedure_ent_cmnts"
            className="form-control my-3"
            value={newSubProcedureEntData.sub_procedure_ent_cmnts}
            onChange={handleInputChange}
            required
          />
        </div>
        &nbsp;
        <input
            type="file"
            name="sub_proc_ent_pic"
            placeholder="Upload file for procedure"
            className="form-control my-3"
            accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
            onChange={(e) => handleImageUpload(e.target.files)}
            multiple
          />
          &nbsp;
        <button className="btn btn-success btn-block my-3" type="submit">
  Add Sub Procedure Entities  </button>
      </form>
      <br></br>
      <button type='button'
          className="btn btn-success btn-block my-3"
          onClick={onClose}
        >
          Cancel
        </button>
      {/* <button
                className="btn btn-success btn-block my-3"
              onClick={() => {
                navigate(`/sub-procedure-ent/${sub_procedure_entity_id}/entity-params`)
              }}
              >
                Add Entity Parameters
              </button> */}
    </div>
  );
}

export default SubProcedureEntityCreate