import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { confirmSignUp } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../../Validation';

const SignInConfirmation = ({ setAuth }) => {
    const navigate = useNavigate();
    const [errorText, setErrorText] = useState('');
    const [inputs, setInputs] = useState({
        email_id: '',
        confirmationCode: ''
      });
      const {email_id, confirmationCode} = inputs;

  const onChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value});
};

  const handleEmailValidation = async () => {
    if (!isValidEmail(email_id)) {
        console.error('Invalid email');
        setErrorText('Invalid Email');
        clearErrorTextAfterDelay();
        return;
      }
    try {
      onConfirmCode();
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const onConfirmCode = async () => {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: inputs.email_id,
        confirmationCode: inputs.confirmationCode,
      });

      // Handle the confirmation response, e.g., navigate to the next step
      console.log('isSignUpComplete:', isSignUpComplete);
      console.log('nextStep:', nextStep);
      navigate('/login');
      // Continue with authentication logic after successful confirmation

      // ... (rest of your authentication logic)
    } catch (err) {
      console.error(err.message);
      toast.error('Invalid confirmation code. Please try again.');
    }
  };

  const clearErrorTextAfterDelay = () => {
    setTimeout(() => {
      setErrorText('');
    }, 5000);
  };

  return (
    <div>
               <Link to={'/login'}>Go Back</Link>
      <div className='form-group col-12 col-md-4'>
        <input
          type='text'
          name='confirmationCode'
          placeholder='Confirmation Code'
          className='form-control my-3'
          value={confirmationCode}
          onChange={onChange}
        />
        {/* ... (validation message if needed) */}
      </div>
      <div className='form-group col-12 col-md-4'>
        <input
            required
            className={`form-control pb-2 ${email_id && !isValidEmail(email_id) ? 'is-invalid' : ''}`}
            type='email'
          name='email_id'
          placeholder='Email id'
          value={email_id}
          onChange={onChange}
        />
       {email_id && !isValidEmail(email_id) && <div className="invalid-feedback">Invalid Email</div>}
    <br />
    {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
      </div>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <button
            className='btn btn-success btn-block'
            onClick={handleEmailValidation}
            disabled={!confirmationCode || !isValidEmail(email_id) }
          >
            Confirm Code
          </button>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default SignInConfirmation;
