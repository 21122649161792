import React, { useEffect, useState } from "react";
import SubProcedureForm from "./forms/SubProcedureForm";
import { useParams } from "react-router-dom";

const CreateSubProcedure = ({onClose, onUpdate}) => {
  const { procedure_id } = useParams();

  useEffect(() => {
    // Fetch procedure data based on procedure_id
  }, [procedure_id]);


  return (
    <div>
      <SubProcedureForm mode="create" procedureId={procedure_id} onClose={onClose} onUpdate={onUpdate} />
      <br></br>
      {/* <button
                className="btn btn-success btn-block my-3"
              onClick={() => {
                navigate(`/sub-procedure/${sub_procedure_id}/add-sub-ent`)
              }}
              >
                Add Sub Procedure Entities
              </button> */}
    </div>
  );
};

export default CreateSubProcedure;
