import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { apiUrl } from "../../environment";
import PatientForm from "./PatientForm";
import FormModal from "../../modals/FormModal";
import Modal from "react-modal";
import axios from "axios";

const PatientCrud = () => {
  const [patients, setPatients] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editPatientId, setEditPatientId] = useState(null);
  const [newPatientData, setNewPatientData] = useState({
    name: "",
    age: "",
    gender: "",
    contact: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchPatientData();
  }, []);

  const fetchPatientData = async () => {
    try {
      const organization_id = localStorage.getItem('organization_id');
      if (!organization_id) {
        throw new Error('Organization ID not found in local storage');
      }
  
      const response = await axios.get(`${apiUrl}/patient/${organization_id}/get`);
  
      const data = response.data;
      setPatients(data.patients);
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };
  

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditing(false);
    setEditPatientId(null);
    setNewPatientData({
      name: "",
      age: "",
      gender: "",
      contact: "",
    });
  };

  const handleAddPatient = async (patientData) => {
    try {
      const response = await fetch(`${apiUrl}/patient/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(patientData),
      });

      if (response.ok) {
        const org_id = localStorage.getItem("organization_id");
        const parseRes = await response.json();
        const orgPatientResponse = await fetch(`${apiUrl}/organization-patient-link/create`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            organization_id: org_id,
            patient_id: parseRes.newPatient.patient_id,
          }),
        });

        if (orgPatientResponse.ok) {
          await fetchPatientData();
          closeModal();
        } else {
          console.error("Failed to link patient to organization.");
        }
      } else {
        console.error("Failed to add a new patient.");
      }
    } catch (error) {
      console.error("Error adding a new patient: ", error);
    }
  };

  const handleUpdatePatient = async (patientData) => {
    try {
      const response = await fetch(`${apiUrl}/patient/${editPatientId}/update`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(patientData),
      });

      if (response.ok) {
        await fetchPatientData();
        closeModal();
      } else {
        console.error("Failed to update the patient.");
      }
    } catch (error) {
      console.error("Error updating the patient: ", error);
    }
  };

  const handleEdit = (patient) => {
    setEditPatientId(patient.patient_id);
    setNewPatientData({
      name: patient.name,
      age: patient.age,
      gender: patient.gender,
      contact: patient.contact,
    });
    setIsEditing(true);
    openModal();
  };

  const [showModal, setShowModal] = useState(false);
  const [deletePatientId, setDeletePatientId] = useState(null);

  const handleDeleteModalOpen = (patientId) => {
    setShowModal(true);
    setDeletePatientId(patientId);
  };

  const handleDeleteModalClose = () => {
    setShowModal(false);
    setDeletePatientId(null);
  };

  const handleDeleteConfirm = async () => {
    if (deletePatientId) {
      try {
        const response = await fetch(`${apiUrl}/patient/${deletePatientId}/delete`, {
          method: "PUT",
        });

        if (response.ok) {
          await fetchPatientData();
        } else {
          console.error("Failed to delete the patient.");
        }
      } catch (error) {
        console.error("Error deleting the patient: ", error);
      }
    }
    handleDeleteModalClose();
  };

  return (
    <div>
      <Header />
      <h2 className="text-center my-5">Patients</h2>

      <button onClick={openModal}>Add Patient</button>

      <FormModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={isEditing ? "Edit Patient" : "Add Patient"}
      >
        <PatientForm
          initialData={newPatientData}
          onSubmit={isEditing ? handleUpdatePatient : handleAddPatient}
          onCancel={closeModal}
          isEditing={isEditing}
        />
      </FormModal>

      {patients.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient) => (
              <tr key={patient.patient_id}>
                <td onClick={() => navigate(`/patient/${patient.patient_id}`)}>
                  {`${patient.name}`}
                </td>
                <td>{patient.age}</td>
                <td>{patient.gender}</td>
                <td>{patient.contact}</td>
                <td>
                  <button className="edit-btn" onClick={() => handleEdit(patient)}>
                    Edit
                  </button>
                  <button className="delete-btn" onClick={() => handleDeleteModalOpen(patient.patient_id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No patients found.</p>
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={handleDeleteModalClose}
        contentLabel="Delete Patient Confirmation"
      >
        <h2>Delete Patient</h2>
        <p>Are you sure you want to delete this patient?</p>
        <button onClick={handleDeleteConfirm}>Yes</button>
        <button onClick={handleDeleteModalClose}>No</button>
      </Modal>
    </div>
  );
};

export default PatientCrud;
