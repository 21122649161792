import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import ImageView from "../images/image-view/ImageView";
import { fetchMultipleImages } from "../images/services/ImageService";
import { findSharedAccessByOrgId, findSharedAccessByUserId } from "../shared-access/services/SharedAccessService";
import FormModal from "../../modals/FormModal";
import CreateSubProcedure from "../sub-procedures/CreateSubProcedure";
import ProcedureEdit from "./ProcedureEdit";
import { fetchProcedureDetails } from "./services/ProcedureService";
import { getPatientById } from "../patients/services/PatientService";
import { deleteSubProcedureRecord } from "../sub-procedures/services/SubProcedureService";
import { deleteSubProcedureEntity } from "../sub-procedure-entities/services/SubProcedureEntityService";
import { deleteEntitiesParameter } from "../entity-parameters/services/EntitiesParameterService";
import SubProcedureEntityCreate from "../sub-procedure-entities/SubProcedureEntityCreate";
import SubProcedureEntityEdit from "../sub-procedure-entities/SubProcedureEntityEdit";
import EntityParamsCreate from "../entity-parameters/EntityParamsCreate";
import EditSubProcedure from "../sub-procedures/EditSubProcedure";
import EntityParamsEdit from "../entity-parameters/EntityParamsEdit";

const ProcedureDetails = () => {
  const user_id = localStorage.getItem("user_id");
  const [procedures, setProcedures] = useState([]);
  const [images, setImages] = useState([]);
  const { procedure_id } = useParams();
  const navigate = useNavigate();
  const [patient_name, setPatientName] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null); // New state for modal content

  const [loading, setLoading] = useState(true);
  const [full_access, setFullAccess] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [showSubmit, setShowSubmit] = useState(false);

  const fetchSharedAccess = async () => {
    try {
      const sharedAccessResponse = await findSharedAccessByUserId(user_id);
      if (sharedAccessResponse) {
        const user_account_id = sharedAccessResponse.sharedAccessRecords[0].user_id;
        if (user_account_id === user_id) {
          setFullAccess(true);
        }
      } else {
        console.error("Failed to fetch shared access");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetchProcedureDetails(procedure_id);
      console.log(response);
      if (response) {
        const data = response
        fetchUserData(data.procedureRecord[0].org_id);
        setProcedures(data.procedureRecord);
        setLoading(false);
        try {
          const patient_id = data.procedureRecord[0].patient_id;
          const getPatientResponse = await getPatientById(patient_id);

          if (getPatientResponse) {
            const patientData =  getPatientResponse;
            console.log(patientData);
            setPatientName(patientData.patient.name);
            try {
              const userAccountId = procedure_id;
              const imageType = "Procedure_pic";
              const imageStatus = "active";
              const imageResponse = await fetchMultipleImages(userAccountId, imageType, imageStatus);

              if (imageResponse) {
                const imageData = imageResponse;
                console.log(imageData);
                setImages(imageData);
              } else {
                console.error("Failed to fetch images:", imageResponse.status);
              }
            } catch (error) {
              console.info("Error fetching images:", error.message);
            }
          } else {
            console.error("Failed to fetch patient data:", getPatientResponse.status);
          }
        } catch (error) {
          console.error("Error fetching patient data:", error.message);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSharedAccess();
  }, [user_id]);

  const fetchUserData = async (organization_id) => {
    const shared_access = await findSharedAccessByOrgId(organization_id);
    console.log(shared_access)
    if (shared_access) {
      setUsers(shared_access?.sharedAccessRecords);
    } else {
      setUsers([]);
      console.error(shared_access.error);
    }
  }

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent(null);
  };

  const subProcedureDelete = async (sub_procedure_id) => {
    try {
      const response = await deleteSubProcedureRecord(sub_procedure_id);
      if (response) {
        await fetchData();
      } else {
        console.error("Failed to delete.");
      }
    } catch (error) {
      console.error("Error deleting: ", error);
    }
  };

  const subProcedureEntDelete = async (sub_procedure_entities_id) => {
    try {
      const response = await deleteSubProcedureEntity(sub_procedure_entities_id);
      if (response) {
        await fetchData();
      } else {
        console.error("Failed to delete.");
      }
    } catch (error) {
      console.error("Error deleting: ", error);
    }
  };

  const entParamsDelete = async (entities_parameters_id) => {
    try {
      const response = await deleteEntitiesParameter(entities_parameters_id);

      if (response) {
        await fetchData();
      } else {
        console.error("Failed to delete.");
      }
    } catch (error) {
      console.error("Error deleting: ", error);
    }
  };

  const handleUserChange = (event) => {
    setShowSubmit(true);
    setSelectedUser(event.target.value);
  };

  const handleSubmit = async () => {
    setShowSubmit(false);
    fetchData();
  };

  return (
    <div>
      <Header />
      <div className="row pt-2">
        <div className="col-md-8 col-12">
          {procedures.map((procedure) => (
            <div key={procedure?.procedure_id}>
              <h2>Procedure Details</h2>
              <p>
                <strong>{patient_name}</strong>
              </p>
              <p>
                <strong>Status:</strong> {procedure?.status}
              </p>
              <p>
                <strong>Procedure Notes:</strong> {procedure?.procedure_notes}
              </p>
              <p>
                <strong>Created Time:</strong>{" "}
                {procedure?.created_time
                  ? new Date(procedure.created_time).toLocaleString()
                  : "N/A"}
              </p>
              <p>
                <strong>Last Updated Time:</strong>{" "}
                {procedure?.last_updated_time
                  ? new Date(procedure.last_updated_time).toLocaleString()
                  : "N/A"}
              </p>
              {full_access && (
                <>
                  <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<ProcedureEdit key={procedure.procedure_id} onClose={closeModal} onUpdate={fetchData}/>)}
                  >
                    Edit
                  </button>
                </>
              )}
              <br />
              {full_access && (
                <>
                  <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<CreateSubProcedure onClose={closeModal} onUpdate={fetchData}/>)}
                  >
                    Add Sub Procedure
                  </button>
                </>
              )}
              <br />
              {Array.isArray(images) && images.length > 0 ? (
  images.map((image, index) => (
    <ImageView
      key={index}
      imageUrl={image.presignedURL}
      imageFormat={image.image_format}
    />
  ))
) : (
  <p>No images available</p>
)}
              <br />
              <h3>Sub Procedure Records</h3>
              {procedure?.sub_procedure_records.length === 0 ? (
                <p>No sub procedure records found.</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th>Sub Procedure ID</th> */}
                      <th>Serial Number</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {procedure?.sub_procedure_records.map((subProcedure) => (
                      <tr key={subProcedure?.sub_procedure_id}>
                        {/* <td>{subProcedure?.sub_procedure_id}</td> */}
                        <td>{subProcedure?.serial_number}</td>
                        <td
                          onClick={() =>
                            navigate(
                              `/sub-procedure/${subProcedure.sub_procedure_id}`
                            )
                          }
                        >
                          {subProcedure?.name}
                        </td>
                        <td>{subProcedure?.type}</td>
                        {full_access && (
                          <td>
                              <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<EditSubProcedure sub_procedure_id={subProcedure.sub_procedure_id} onClose={closeModal} onUpdate={fetchData}/>)}
                  >
                    Edit
                  </button>
                            &nbsp;
                            <button
                              className="delete-btn"
                              onClick={() =>
                                subProcedureDelete(subProcedure.sub_procedure_id)
                              }
                            >
                              Delete
                            </button>
                            &nbsp;
                            <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<SubProcedureEntityCreate onClose={closeModal} onUpdate={fetchData} sub_procedure_id={subProcedure.sub_procedure_id}/>)}
                  >
                   Add Sub Procedure Entities
                  </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <h3>Sub Procedure Entities</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th>Comments</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {procedure?.sub_procedure_records?.length === 0 ||
                  procedure?.sub_procedure_records.every(
                    (subProcedure) =>
                      !subProcedure?.sub_procedure_entities ||
                      subProcedure?.sub_procedure_entities.length === 0
                  ) ? (
                    <tr>
                      <td colSpan="2">No sub procedure entities found.</td>
                    </tr>
                  ) : (
                    procedure?.sub_procedure_records?.map((subProcedure) =>
                      subProcedure?.sub_procedure_entities
                        ?.filter(
                          (sub_procedure_entities) =>
                            sub_procedure_entities !== null
                        ) // Filter out null entities
                        .map((sub_procedure_entities) => (
                          <tr
                            key={sub_procedure_entities?.sub_procedure_entities_id}
                          >
                            <td>
                              {sub_procedure_entities?.comments}
                            </td>
                            {full_access && (
                              <td>
                                 <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<SubProcedureEntityEdit onClose={closeModal} onUpdate={fetchData} sub_procedure_entities_id={sub_procedure_entities.sub_procedure_entities_id}/>)}
                  >
                    Edit
                  </button>
                                &nbsp;
                                <button
                                  className="delete-btn"
                                  onClick={() =>
                                    subProcedureEntDelete(
                                      sub_procedure_entities.sub_procedure_entities_id
                                    )
                                  }
                                >
                                  Delete
                                </button>
                                &nbsp;
                                <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<EntityParamsCreate onClose={closeModal} onUpdate={fetchData} sub_procedure_entities_id={sub_procedure_entities?.sub_procedure_entities_id}/>)}
                  >
                    Add Entity Parameters
                  </button>
                              </td>
                            )}
                          </tr>
                        ))
                    )
                  )}
                </tbody>
              </table>

              <h3>Entity Parameters</h3>
              {procedure?.sub_procedure_records?.length === 0 ||
              procedure?.sub_procedure_records.every(
                (subProcedure) =>
                  !subProcedure?.sub_procedure_entities ||
                  subProcedure?.sub_procedure_entities.length === 0 ||
                  subProcedure?.sub_procedure_entities.every(
                    (entity) =>
                      !entity?.entity_parameters ||
                      entity?.entity_parameters.length === 0 ||
                      entity?.entity_parameters.every((param) => param === null)
                  )
              ) ? (
                <p>No entity parameters found.</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Units</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {procedure?.sub_procedure_records?.map((subProcedure) =>
                      subProcedure?.sub_procedure_entities
                        ?.filter(
                          (sub_procedure_entities) =>
                            sub_procedure_entities !== null
                        ) // Filter out null entities
                        .map((sub_procedure_entities) =>
                          sub_procedure_entities?.entity_parameters
                            ?.filter((param) => param !== null) // Filter out null parameters
                            ?.map((entityParameter) => (
                              <tr key={entityParameter?.entities_parameters_id}>
                                <td>{entityParameter?.name}</td>
                                <td>{entityParameter?.value}</td>
                                <td>{entityParameter?.units}</td>
                                {full_access && (
                                  <td>
                                     <button
                    className="btn btn-success btn-block my-3"
                    onClick={() => openModal(<EntityParamsEdit onClose={closeModal} onUpdate={fetchData} entities_parameters_id={entityParameter.entities_parameters_id}/>)}
                  >
                    Edit
                  </button>
                                    <button
                                      className="delete-btn"
                                      onClick={() =>
                                        entParamsDelete(
                                          entityParameter.entities_parameters_id
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))
                        )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ))}
        </div>
        <div>
      {!users || users.length === 0 ? (
        <div>
          <p>No users available to share access.</p>
        </div>
      ) : (
        <>
        {full_access && (
          <div className="col-md-4 col-12">
          <h5>Share access?</h5>
          <div>
            <label htmlFor="user-select">Select Users:</label>
            <select id="user-select" value={selectedUser} onChange={handleUserChange}>
              <option value="" disabled>Select a user</option>
              {users.map(user => (
                <option key={user.user_id} value={user.user_id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </select>
          </div>
          {showSubmit && (
            <button onClick={handleSubmit}>Submit</button>
          )}
        </div>
        )}
        </>
      )}
    </div>
      </div>
      <FormModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Form Modal">
        {modalContent}
      </FormModal>
    </div>
  );
};

export default ProcedureDetails;
