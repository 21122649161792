// useForm.js
import { useState } from 'react';

const UseForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return [values, handleInputChange, setValues];
};

export default UseForm;
