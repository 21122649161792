import React from 'react';
import { useNavigate } from 'react-router-dom';
import bgimg from '../../assets/bg-image.jpeg';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div className='row'>
        <div className='col-12 col-md-4'>

        </div>
        <div className='col-12 col-md-4'>

        </div>
        <div className='col-12 col-md-4'>
        <p style={{ fontSize: '24px'}}>
           <span style={{ cursor: 'pointer'}} onClick= {() => navigate('/login')}> Click here to go to the login page.</span>
        </p>
        </div>
        </div>
      </div>
      <img
        src={bgimg}
        alt="Uploaded Image"
        style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
      />
    </div>
  );
};

export default Home;
