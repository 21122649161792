import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ImageView from '../images/image-view/ImageView';
import { apiUrl } from '../../environment';
import Header from '../header/Header';

const SubProcedureEntityDetails = () => {
    const { sub_procedure_entities_id } = useParams();
    const [sub_procedures_ent, setSubProceduresEnt] = useState([]);
  const [images, setImages] = useState([]);

    useEffect(() => {
        fetchData();
      }, [sub_procedure_entities_id]);
    
    
      const fetchData = async () => {
        try {
          const response = await fetch(`${apiUrl}/sub-procedure-entities/${sub_procedure_entities_id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            setSubProceduresEnt(data.subProcedureEntity);
            
            try {
              const userAccountId = sub_procedure_entities_id;
              const image_type = 'sub_procedure_entities_pic';
              const image_status = 'active';
              const imageResponse = await fetch(`${apiUrl}/images/${userAccountId}/${image_type}/${image_status}/multiple`, {
                method: "GET",
                headers: { "authorization": localStorage.authorization },
              });
            
              if (imageResponse.ok) {
              let imageData = [];
              imageData = await imageResponse.json();
              setImages(imageData);
              } else {
                console.error("Failed to fetch images:", imageResponse.status);
              }
                      
            } catch (error) {
              console.error("Error fetching images:", error.message);
            }
          } else {
            throw new Error("Failed to fetch data");
          }
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      return (
        <div>
            <Header />
            <h2>Sub Procedure Entities Details</h2>
            <p><strong>{sub_procedures_ent.comments}</strong></p>
            {images.map((image, index) => (
                <ImageView key={index} imageUrl={image.presignedURL} imageFormat={image.image_format} />
            ))}
        </div>
    )
}

export default SubProcedureEntityDetails