import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationMembers from "../organizations/OrganizationMembers";
import OrganizationDetails from "../organizations/OrganizationDetails";
import FormModal from "../../modals/FormModal";
import ProcedureCreate from "./ProcedureCreate";
import Header from "../header/Header";
import { findSharedAccessByUserId } from "../shared-access/services/SharedAccessService";
import { deleteProcedureRecord, fetchProcedureRecordsByOrgId } from "./services/ProcedureService";

const Procedures = () => {
  const [procedures, setProcedures] = useState([]);
  const user_id = localStorage.getItem("user_id");
  const [delete_procedure_id, setDeleteProcedureId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { organization_id } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [full_access, setFullAccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [user_id]);

  const fetchData = async () => {
    try {
      const response = await fetchProcedureRecordsByOrgId(organization_id);
      if (response) {
        const proced_data = await response;
        setProcedures(proced_data.procedureRecord.sort((a, b) => new Date(a.created_time) - new Date(b.created_time)));
        fetchSharedAccess();
      } else {
        throw new Error("Failed to fetch procedures data");
      }
    } catch (error) {
      console.error("Error fetching procedures data:", error);
    }
  };

  const fetchSharedAccess = async () => {
    try {
      const sharedAccessResponse = await findSharedAccessByUserId(user_id);
      if (sharedAccessResponse) {
        const user_account_id = sharedAccessResponse.sharedAccessRecords[0].user_id;
        if (user_account_id === user_id) {
          setFullAccess(true);
        }
      } else {
        console.error("Failed to fetch shared access");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteModalOpen = (procedure_id) => {
    setShowModal(true);
    setDeleteProcedureId(procedure_id);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDeleteModalClose = () => {
    setShowModal(false);
    setDeleteProcedureId(null);
  };

  const handleDeleteConfirm = async () => {
    if (delete_procedure_id) {
      try {
        const response = await deleteProcedureRecord(delete_procedure_id);
        if (response) {
          await fetchData();
        } else {
          console.error("Failed to delete the procedure.");
        }
      } catch (error) {
        console.error("Error deleting the procedure: ", error);
      }
    }
    handleDeleteModalClose();
  };

  return (
    <>
      <div>
        <Header />
        <div>
          <button className="bg-primary" onClick={openModal}>
            Add Procedure
          </button>
          <FormModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Form Modal"
          >
            <ProcedureCreate />
          </FormModal>
        </div>
        {procedures.length > 0 ? (
          <>
            <table className="user-table">
              <thead>
                <tr>
                  <th>Serial Number</th>
                  <th>Status</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {procedures.map((procedureRecord, index) => (
                  <tr key={procedureRecord.procedure_id}>
                    <td>{index + 1}</td>
                    <td
                      onClick={() => navigate(`/procedure/${procedureRecord.procedure_id}`)}
                    >
                      {`${procedureRecord.procedure_notes}`}
                    </td>
                    <td>{procedureRecord.status}</td>
                    {full_access && (
                      <td>
                        <button
                          className="delete-btn"
                          onClick={() =>
                            handleDeleteModalOpen(procedureRecord.procedure_id)
                          }
                        >
                          Delete
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>No procedures found.</p>
        )}

        <Modal
          isOpen={showModal}
          onRequestClose={handleDeleteModalClose}
          contentLabel="Delete User Confirmation"
        >
          <h2>Delete Procedure Record</h2>
          <p>Are you sure you want to delete this Procedure Record?</p>
          <button onClick={handleDeleteConfirm}>Yes</button>
          <button onClick={handleDeleteModalClose}>No</button>
        </Modal>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6 col-12">
          <p><strong>Organization Members :</strong></p>
          <OrganizationMembers />
          <p><strong>Organization Details :</strong></p>
          <OrganizationDetails organization_id={organization_id} />
        </div>
      </div>
    </>
  );
};

export default Procedures;
