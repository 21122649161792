import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiUrl } from '../../../environment';
import ImageEdit from '../../images/image-edit/ImageEdit';

const SubProcedureForm = ({ mode, subProcedureData, procedureId, onClose, onUpdate }) => {
  const { sub_procedure_id } = useParams();
  const [newSubProcedureData, setNewSubProcedureData] = useState({
    sub_procedure_name: '',
    sub_procedure_type: '',
    serial_number: ''
  });
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (mode === 'edit' && subProcedureData) {
      setNewSubProcedureData({
        sub_procedure_name: subProcedureData.name,
        sub_procedure_type: subProcedureData.type,
        serial_number: subProcedureData.serial_number
      });
      fetchImages(subProcedureData.id);
    }
  }, [mode, subProcedureData]);

  useEffect(() => {
    // Check if all required fields are filled
    const { sub_procedure_name, sub_procedure_type, serial_number } = newSubProcedureData;
    setIsFormValid(sub_procedure_name.trim() !== '' && sub_procedure_type.trim() !== '' && serial_number !== '');
  }, [newSubProcedureData]);

  const fetchImages = async (subProcedureId) => {
    try {
      const imageResponse = await fetch(`${apiUrl}/images/${subProcedureId}/sub_procedure_pic/active/multiple`, {
        method: "GET",
        headers: { "authorization": localStorage.authorization },
      });

      if (imageResponse.ok) {
        const imageData = await imageResponse.json();
        setImages(imageData);
      } else {
        console.error("Failed to fetch images:", imageResponse.status);
      }
    } catch (error) {
      console.error("Error fetching images:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubProcedureData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (files) => {
    if (files) {
      const fileList = Object.values(files); // Convert object to array
      setSelectedFiles(fileList);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mode === 'create') {
      addNewSub();
    } else {
      updateSubProcedureRecord();
    }
  };

  const addNewSub = async () => {
    try {
      const response = await fetch(`${apiUrl}/sub-procedures/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          procedure_id: procedureId,
          name: newSubProcedureData.sub_procedure_name,
          type: newSubProcedureData.sub_procedure_type,
          status: "active",
          serial_number: newSubProcedureData.serial_number
        }),
      });
      if (response.ok) {
        const parseRes = await response.json();
        const sub_procedure_id = parseRes.newSubProcedureRecord.sub_procedure_id;
        if (selectedFiles.length > 0) {
          uploadImages(sub_procedure_id);
        }
        onUpdate();
        onClose();
        navigate(`/procedure/${procedureId}`);
      } else {
        const errorData = await response.json();
        console.error("Error while creating new sub procedure record:", errorData.message);
      }
    } catch (error) {
      console.error("Error while creating new sub procedure record:", error);
    }
  };

  const updateSubProcedureRecord = async () => {
    try {
      const response = await fetch(`${apiUrl}/sub-procedures/${sub_procedure_id}/update`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          procedure_id: procedureId,
          name: newSubProcedureData.sub_procedure_name,
          type: newSubProcedureData.sub_procedure_type,
          serial_number: newSubProcedureData.serial_number
        }),
      });

      if (response.ok) {
        if (selectedFiles.length > 0) {
          uploadImages(sub_procedure_id);
        }
        onClose();
        navigate(`/procedure/${procedureId}`);
      } else {
        const errorData = await response.json();
        console.error("Failed to update procedure record:", errorData.message);
      }
    } catch (error) {
      console.error("Error updating procedure record:", error);
    }
  };

  const uploadImages = async (subProcedureId) => {
    const formData = new FormData();
    formData.append("user_account_id", subProcedureId);
    if (Array.isArray(selectedFiles)) {
      selectedFiles.forEach((file) => {
        formData.append(`sub_proc_pic`, file);
        formData.append("image_format", file.type);
      });
    } else {
      console.error("selectedFiles is not an array");
    }
    formData.append("image_type", "sub_procedure_pic");
    formData.append("image_status", "active");

    const fieldName = "sub_proc_pic";
    const imageUploadResponse = await fetch(
      `${apiUrl}/images/create-multiple/${fieldName}`,
      {
        method: "POST",
        body: formData,
      }
    );
    if (!imageUploadResponse.ok) {
      console.error("Failed to upload images!");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="sub_procedure_name">Sub Procedure Name</label>
          <input
            type="text"
            name="sub_procedure_name"
            className="form-control my-3"
            value={newSubProcedureData.sub_procedure_name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sub_procedure_type">Sub Procedure Type</label>
          <input
            type="text"
            name="sub_procedure_type"
            className="form-control my-3"
            value={newSubProcedureData.sub_procedure_type}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="serial_number">Serial Number</label>
          <input
            type="number"
            name="serial_number"
            className="form-control my-3"
            value={newSubProcedureData.serial_number}
            onChange={handleInputChange}
            required
          />
        </div>
        {mode === 'edit' && images.map((image, index) => (
          <ImageEdit
            key={index} imageUrl={image.presignedURL} imageFormat={image.image_format} file_id={image.file_id} userAccountId={sub_procedure_id} image_type={'sub_procedure_pic'} />
        ))}
        <br />
        <div>
          <label>{mode === 'create' ? 'Add a file' : 'Add a file'}</label>
          <input
            type="file"
            name="sub_proc_pic"
            placeholder="Upload file for sub procedure"
            className="form-control my-3"
            accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
            onChange={(e) => handleImageUpload(e.target.files)}
            multiple
          />
        </div>
        <button className="btn btn-success btn-block my-3" type="submit" disabled={!isFormValid}>
          {mode === 'create' ? 'Add Sub Procedure' : 'Update Sub Procedure'}
        </button> &nbsp;
        <button
          className="btn btn-danger btn-block my-3"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default SubProcedureForm;
