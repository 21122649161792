import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createOrganizationPatientLink, getPatientFromOrganizationPatientLink } from "../organizations/services/OrganizationService";
import { createPatient } from "../patients/services/PatientService";
import { createProcedureRecord } from "./services/ProcedureService";
import ProcedureForm from "./forms/ProcedureForm";
import { uploadMultipleImages } from "../images/services/ImageService";
import { createSharedAccessEntry } from "../shared-access/services/SharedAccessService";

const ProcedureCreate = () => {
  const [patientData, setPatientData] = useState([]);
  const org_id = useParams();
  const user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  console.log(org_id.organization_id)

  useEffect(() => {
    const fetchPatientData = async () => {
      const orgPatientData = await getPatientFromOrganizationPatientLink(org_id.organization_id);
      if (orgPatientData) {
        setPatientData(orgPatientData.organizationPatientLink);
      }
    };

    fetchPatientData();
  }, [org_id.organization_id]);

  const handleSubmit = async (formData, selectedFiles) => {
    try {
      console.log(formData)
      if (formData.new_patient_name === "") {
        const matchingPatient = patientData.find((patient) => patient.name === formData.patient_name);
        if (!matchingPatient) {
          throw new Error("Patient not found in patient data");
        }
        await createProcedureForExistingPatient({ ...formData, selectedFiles }, matchingPatient.patient_id);
      } else if (formData.patient_name === "") {
        await createProcedureForNewPatient({ ...formData, selectedFiles });
      }
    } catch (error) {
      console.error("Error creating procedure:", error);
      throw error;
    }
  };

  const createProcedureForExistingPatient = async (data, patient_id) => {
    try {
      const procedureData = {
        procedure_notes: data.procedure_notes,
        status: data.procedure_status,
        patient_id,
        doctor_id: user_id, 
        org_id: org_id.organization_id,
        procedure_status: "active",
      };
  
      const procedureResponse = await createProcedureRecord(procedureData);
      console.log(data?.selectedFiles);
      console.log(procedureResponse);
  
      if (procedureResponse) {
        const procedure_id = procedureResponse.newProcedureRecord.procedure_id;
        const imageType = 'Procedure_pic';
        const entityId = procedure_id;
        const selectedFiles = Array.isArray(data?.selectedFiles) ? data.selectedFiles : [data.selectedFiles];
        const fieldName = "proc_pic";
  
        await uploadMultipleImages(entityId, selectedFiles, fieldName, imageType);
  
        // Create shared access entry
        const sharedAccessData = {
          user_id,
          access: 'full',
          procedure_id,
        };
  
        const sharedAccessResponse = await createSharedAccessEntry(sharedAccessData);
        console.log(sharedAccessResponse);
  
        navigate("/dashboard");
      } else {
        throw new Error("Failed to create procedure");
      }
    } catch (error) {
      console.error(error.message);
      throw new Error('An error occurred while processing the procedure creation.');
    }
  };
  

  const createProcedureForNewPatient = async (formData) => {
    const patientData = {
      name: formData.new_patient_name,
      age: formData.new_patient_age,
      gender: formData.new_patient_gender,
      contact: formData.new_patient_contact,
      govt_id: formData.govt_id,
      govt_id_type: formData.govt_id_type,
    };

    const newPatientData = await createPatient(patientData)
    if (newPatientData) {
      console.log(newPatientData)
      console.log(newPatientData.newPatient.patient_id)
      const organizationPatientLinkData = {
        organization_id: org_id.organization_id,
        patient_id: newPatientData.newPatient.patient_id,
      };

      const orgPatientLinkResponse = await createOrganizationPatientLink(organizationPatientLinkData);

      if (orgPatientLinkResponse) {
        await createProcedureForExistingPatient(formData, newPatientData.newPatient.patient_id);
      } else {
        throw new Error("Failed to create organization-patient link");
      }
    } else {
      throw new Error("Failed to create patient");
    }
  };

  return (
    <div>
      <ProcedureForm
        initialValues={{
          patient_name: "",
          new_patient_name: "",
          new_patient_age: "",
          new_patient_gender: "",
          new_patient_contact: "",
          procedure_status: "",
          procedure_notes: "",
          patientData: patientData
        }}
        onSubmit={(formData, selectedFiles) => handleSubmit(formData, selectedFiles)}
      />
    </div>
  );
};

export default ProcedureCreate;
