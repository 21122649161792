import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import OrganizationJoinModel from './modals/OrganizationJoinModel';
import { fetchOrganizationData, fetchApprovalsByUserId, getOrganizationUserLinkByUserId } from './services/OrganizationService';

const OrganizationProcedures = () => {
  const [organizations, setOrganizations] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const navigate = useNavigate();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchOrganizations = async () => {
    try {
      const orgData = await fetchOrganizationData();
      if (!Array.isArray(orgData)) {
        console.error('No organization data found');
        return [];
      }
      return orgData;
    } catch (error) {
      console.error('Error fetching organization data:', error);
      return [];
    }
  };

  const fetchApprovals = async () => {
    try {
      const user_id = localStorage.getItem('user_id');
      const approvalData = await getOrganizationUserLinkByUserId(user_id); 
      console.log('Approval Data:', approvalData);
  
      if (approvalData && approvalData.organizationUserLinkData) {
        const orgUserLinkData = Array.isArray(approvalData.organizationUserLinkData)
          ? approvalData.organizationUserLinkData
          : [approvalData.organizationUserLinkData];
        setApprovals(orgUserLinkData);
        console.log('Approvals set:', orgUserLinkData);
      } else {
        setApprovals([]);
        console.log('No approvals found');
      }
    } catch (error) {
      console.error('Error fetching approvals:', error);
      setApprovals([]);
    }
  };
  
  const fetchData = async () => {
    const orgs = await fetchOrganizations();
    console.log('Fetched Organizations:', orgs);
    setOrganizations(orgs);
    await fetchApprovals();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRedirectToProcedures = (organization_id) => {
    navigate(`/procedures/${organization_id}`);
  };

  const handleJoinClick = (org) => {
    setSelectedOrg(org);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchData();
  };

  const getStatusForOrg = (organization_id) => {
    console.log('Organization ID:', organization_id);
    console.log('Approvals:', approvals);
    if (!approvals || !Array.isArray(approvals)) {
      return null;
    }
    const approval = approvals.find(a => a.organization_id === organization_id);
    console.log('Approval:', approval)
    return approval ? approval.status : null;
  };

  return (
    <div>
      <div className='row'>
        <div className='col-md-12 col-12'>
          {organizations?.length > 0 ? (
            <table className="user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact Email</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {organizations?.map((org) => {
                  const status = getStatusForOrg(org.organization_id);
                  console.log('Status for org', org.organization_id, ':', status);
                  return (
                    <tr key={org.organization_id}>
                      <td>{org.name}</td>
                      <td>{org.contact_email}</td>
                      <td>{org.description}</td>
                      <td>
                        {status === 'active' ? (
                          <button onClick={() => handleRedirectToProcedures(org.organization_id)}>View</button>
                        ) : status === 'pending' ? (
                          <button disabled>Pending Request</button>
                        ) : (
                          <button onClick={() => handleJoinClick(org)}>Join</button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No organizations found.</p>
          )}
        </div>
      </div>
      <Modal isOpen={showModal}>
        <OrganizationJoinModel
          organizationId={selectedOrg ? selectedOrg.organization_id : null}
          onClose={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default OrganizationProcedures;
